<template>
    <div id="app">
        <transition name="slide"><router-view /></transition>
    </div>
</template>
<script>
    export default {
        name: "APP",
		mounted() {
		      let recaptchaScript = document.createElement('script')
			  let recaptchaScript2 = document.createElement('script')
			  let recaptchaScript3 = document.createElement('script')
			  let recaptchaScript4 = document.createElement('script')
			  
			  recaptchaScript4.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.1/jquery.min.js')
			  document.head.appendChild(recaptchaScript4)
		      recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.2.2/js/bootstrap.min.js')
		      document.head.appendChild(recaptchaScript)
			  recaptchaScript2.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/mdbootstrap/4.20.0/js/mdb.min.js')
			  document.head.appendChild(recaptchaScript2)
		    }
		
    }
</script>
<style>
	
  @import 'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css';
  @import 'https://cdn.jsdelivr.net/npm/@animxyz/core';
 
</style>
<style lang="scss" scoped>
    /*ทุกหน้าเป็นแบบสาธารณะcss */
	@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    @import url("assets/styles/iconfont.css");
	.el-tabs__nav {
	   
	    border-radius: 10px 10px 0px 0px !important;
	}
    @import "assets/styles/main.scss";
	.el-header{
	    padding: 0;
	    height: 174px !important;
	}
	/*สาธารณะทุกหน้าcss */
	@import url("assets/styles/iconfont.css");
	@import "assets/styles/main.scss";
	
	@font-face {
	    font-family: 'sukhumvittadmai';
	    src: url("https://overmine.org/SukhumvitTadmai-Text.ttf") format("truetype");
	}
	@font-face {
	    font-family: 'sukhumvittadmai2';
	    src: url("https://overmine.org/SukhumvitTadmaiBold.otf") format("OpenType");
	}
	
	
	view,h1,h2,h3,h4,h5,h6,text,div,a,el-input,el-button,del,p{
		font-family: 'Prompt', sans-serif !important;
		font-weight: 400;
		    
	}

	
	.el-header {
    padding: 0;
    height: 174px !important;
	}
	.el-pagination .el-pagination__rightwrapper {
	    margin-left: -13px;
	}
	.el-pagination span {
	  display: inline-block;
	  font-size: 13px;
	  font-family: "sukhumvittadmai", sans-serif !important;
	  min-width: 68.5px;
	  height: 34px;
	  padding: 4px;
	  border-radius: 15px; 
	  line-height: 28px;
	  vertical-align: top;
	  box-sizing: border-box;
	}section {
    display: block;
    position: relative;
    padding: 0px 0;
    width: 100%;
}

.el-footer{
    padding: 0;
    height: auto !important;
    background-color: #fff;
    padding-top: 0px;
}.el-tabs__item {
       font-family: 'sukhumvittadmai2';
}

.el-notification right{
	
	  font-family: 'sukhumvittadmai2';
}

p:last-child {
    margin-bottom: 0;
    font-family: 'Prompt', sans-serif !important;
}.el-notification__title {
    font-weight: bold;
    font-size: 16px;
    color: #303133;
    margin: 0;
    font-family: 'Prompt', sans-serif !important;
}


</style>
