const langList = ["en-us", "en-us"]

var locale = "en-us" //ตั้งค่าภาษา，uni.getStorageSync('lang') ||

export default {
    langList: ["en-us", "en-us"],
    /**
     * แยกวิเคราะห์หลายภาษา
     * @param {Object} field สนาม
     * @param {Object} route วัตถุการกําหนดเส้นทาง
     */
    lang(field, route) {
        let name = "",
            module = ""
        if (route) {
            name = route.name
            module = route.meta.module
            if (route.path == "/" || route.name == "index") {
                name = "index"
                module = "index"
            }
        } else {
            name = vue.$route.name
            module = vue.$route.meta.module
        }

        if (!name || !module) return
		
        var value = ""
        try {
            // ชุดภาษาทั่วไป
            var lang = require("../lang/" + locale + "/common.js").lang

            // ชุดภาษาของหน้าปัจจุบัน
            let currentViewLang = require("../lang/" + locale + "/" + module + "/" + name + ".js").lang

            Object.assign(lang, currentViewLang)

            var arr = field.split(".")
            if (arr.length > 1) {
                for (let i in arr) {
                    var next = parseInt(i) + 1
                    if (next < arr.length) {
                        value = lang[arr[i]][arr[next]]
                    }
                }
            } else {
                value = lang[field]
            }
        } catch (e) {
            value = field
        }

        if (arguments.length > 1) {
            //มีพารามิเตอร์,จําเป็นต้องเปลี่ยนใหม่
            for (var i = 1; i < arguments.length; i++) {
                value = value.replace("{" + (i - 1) + "}", arguments[i])
            }
        }

        if (value == undefined || (value == "title" && field == "title")) value = "" // field
        return value
    },
    //สลับภาษา
    change(value) {
        if (!vue) return

        vue.$store.commit("lang", value)
        locale = vue.$store.state.lang //ตั้งค่าภาษา
        this.refresh()
    },
    //รีเฟรชชื่อเรื่อง
    refresh(route) {
        window.document.title = this.lang("title", route)
    },
    // รับรายการชุดภาษา
    list() {
        var list = []
        try {
            //ชุดภาษาทั่วไป
            for (var i = 0; i < langList.length; i++) {
                let item = require("../lang/" + langList[i] + "/common.js").lang
                list.push({
                    name: item.common.name,
                    value: langList[i]
                })
            }
        } catch (e) {
            // "ไม่พบชุดภาษา:", '../../lang/' + locale + '/common.js'
        }
        return list
    },
    // รับการแสดงภาษาภาคสนาม
    getLangField(field, route) {
        return this.lang(field, route) || ""
    }
}
