const getters = {
	// ผู้ใช้TOKRN
	token: state => state.member.token,
	lang: state => state.app.lang,
	city: state => state.app.city,
	locationRegion: state => state.app.locationRegion,
	// ระยะเวลาการเข้าสู่ระบบอัตโนมัติ
	autoLoginRange: state => state.member.autoLoginRange,
	wapQrcode: state => state.site.siteQrCode,
	// รายละเอียดการเป็นสมาชิก
	member: state => state.member.member,

	// ระยะเวลาที่สมาชิกจะเข้าสู่ระบบโดยอัตโนมัติ
	autoLoginRange: state => state.member.autoLoginRange,

	copyRight: state => state.site.copyRight,
	siteInfo: state => state.site.siteInfo,
	addonIsExit: state => state.site.addons,

	// จํานวนสินค้าทั้งหมดในตะกร้าสินค้า
	cartCount: state => state.cart.cartCount,
	// 
	is_show: state => state.app.is_show,

	defaultGoodsImage: state => state.site.defaultFiles.default_goods_img,
	defaultHeadImage: state => state.site.defaultFiles.default_headimg,
	defaultShopImage: state => state.site.defaultFiles.default_shop_img,
	defaultCityImage: state => state.site.defaultFiles.default_city_img,
	defaultSupplyImage: state => state.site.defaultFiles.default_supply_img,
	defaultStoreImage: state => state.site.defaultFiles.default_store_img,
	defaultCategoryImage: state => state.site.defaultFiles.default_category_img,
	defaultBrandImage: state => state.site.defaultFiles.default_brand_img,

	// คําสั่งซื้อที่รอดําเนินการปกติ
	orderCreateGoodsData: state => state.order.orderCreateGoodsData,

	//กลุ่มคําสั่งซื้อที่รอดําเนินการ
	groupbuyOrderCreateData: state => state.order.groupbuyOrderCreateData,

	//ข้อตกลงฟ้าผ่าสําหรับคําสั่งซื้อที่รอดําเนินการ
	seckillOrderCreateData: state => state.order.seckillOrderCreateData,

	//แพคเกจคําสั่งผสมที่รอดําเนินการคําสั่งซื้อ
	comboOrderCreateData: state => state.order.comboOrderCreateData
}

export default getters;
