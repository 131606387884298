import http from "../../utils/http"
/**
 * รับข้อมูลสมาชิกพื้นฐาน
 */
export function memberInfo(params) {
    return http({
        url: "/api/member/info",
        data: params,
        forceLogin: true
    })
}

/**
 * จํานวนคําสั่งซื้อ
 */
export function orderNum(params) {
    return http({
        url: "/api/order/num",
        data: params,
        forceLogin: true
    })
}

/**
 * จํานวนคูปอง
 */
export function couponNum(params) {
    return http({
        url: "/coupon/api/coupon/num",
        data: params,
        forceLogin: true
    })
}

/**
 * รอยเท้าของฉัน
 */
export function footprint(params) {
    return http({
        url: "/api/goodsbrowse/page",
        data: params,
        forceLogin: true
    })
}
/**
 * รายชื่อระดับสมาชิก
 */
export function levelList(params) {
    return http({
        url: "/api/memberlevel/lists",
        data: params,
        forceLogin: true
    })
}