import http from "../utils/http"

/**
 * รับข้อมูลเว็บไซต์
 */
export function websiteInfo(params) {
    return http({
        url: "/api/site/info",
        data: params
    })
}

/**
 * รับข้อมูลลิขสิทธิ์
 */
export function copyRight(params) {
    return http({
        url: "/api/config/copyright",
        data: params
    })
}

/**
 * นํามาwapสิ้นสุดรหัส QR
 */
export function wapQrcode(params) {
    return http({
        url: "/api/site/wapqrcode",
        data: params
    })
}

export function siteDefaultFiles(params) {
    return http({
        url: "/api/config/defaultimg",
        data: params
    })
}

/**
 * รับรหัส QR ของมินิโปรแกรมหน้าแรก
 */
export function weQrcode(params) {
    return http({
        url: "weapp/api/weapp/qrcode",
        data: params
    })
}
/**
 * กราฟิกโฆษณา
 * @param {Object} params พารามิเตอร์
 */
export function adList(params) {
    return http({
        url: "/api/adv/detail",
        data: params
    })
}

/**
 * รับบริการสําหรับผู้ขาย
 */
export function shopServiceLists(params) {
    return http({
        url: "/api/goods/service",
        data: params
    })
}

/**
 * เชื่อม โยง
 * @param {Object} params พารามิเตอร์
 */
export function friendlyLink(params) {
    return http({
        url: "/api/pc/friendlyLink",
        data: params
    })
}

/**
 * การเดินเรือ
 * @param {Object} params พารามิเตอร์
 */
export function navList(params) {
    return http({
        url: "/api/pc/navList",
        data: params
    })
}

/**
 * รับรหัสยืนยัน
 */
export function captcha(params) {
    return http({
        url: "/api/captcha/captcha",
        data: params
    })
}

export function getSiteStatus() {
    return http({
        url: "/api/site/status"
    })
}
// เปิดบริการลูกค้า
export function shopServiceOpen(params) {
    return http({
        url: "/api/config/servicer",
        data: params
    })
}