export default {
	// apiที่อยู่ที่ร้องขอ
	baseUrl: 'https://partsstore.vueapp.shop',
	// ชื่อโดเมนรูปภาพ
	imgDomain: 'https://partsstore.vueapp.shop',
	// ชื่อโดเมนส่วนหน้า
	webDomain: 'https://partsstore.vueapp.shop',
	// แผนที่เทนเซ็นต์key
	mpKey: '',
	// ลูกค้า
	webSocket: '',
	//ด้านท้องถิ่นอย่างแข็งขันให้กับเซิร์ฟเวอร์pingเวลา, 0 ไม่ได้เปิดอยู่ , ในไม่กี่วินาที
	pingInterval: 1500
}