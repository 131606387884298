import http from "../../utils/http"

/**
 * รับโครงสร้างต้นไม้เรียกดูผลิตภัณฑ์
 */
export function tree(params) {
    return http({
        url: "/api/goodscategory/tree",
        data: params
    })
}

/**
 * รับข้อมูลหมวดหมู่ผลิตภัณฑ์
 * @param {Object} params พารามิเตอร์ category_id:1
 */
export function goodsCategoryInfo(params) {
    return http({
        url: "/api/goodscategory/info",
        data: params
    })
}

/**
 * รับการกําหนดค่าการจัดประเภท
 */
export function categoryConfig(params) {
    return http({
        url: "/api/config/categoryconfig",
        data: params
    })
}
