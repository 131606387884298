<template>
	<div class="header">
		<ns-header-top />
		<ns-header-mid />

		
			<div class="header__bottom header__padding header__bottom-border" style="    border-bottom: 1px solid #e6e6e6;">
			                    <div class="container-fluid"  style="max-width: 1800px;     text-align: center;">
			                        <div class="row align-items-center">
			                            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-6 col-6">
			                              <div class="header__bottom-left d-flex d-xl-block align-items-center">
			                                <div class="side-menu d-xl-none mr-20">
			                                  <button type="button" class="side-menu-btn offcanvas-toggle-btn"><i class="fas fa-bars"></i></button>
			                                </div>
			                                <div class="main-menu d-none d-md-block">
			                                    <nav id="mobile-menu-2" style="display: block;">
			                                        <ul>
														<li style="    font-size: 16px;"><a  style="  font-family: 'sukhumvittadmai2';  font-size: 16px;" >หมวดหมู่ <i class="far fa-angle-down"></i></a>
														                                                <ul class="submenu">
														                                                    <li v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1"
					  >
					  
					  <router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }" 
						style="" >{{item.category_name }} <i v-if="item.child_list" class="far fa-angle-down"></i> </router-link>
						
						
						
						    <ul class="submenu" v-if="item.child_list" style="right: -72px;">
						        <li v-for="(second_item, second_index) in item.child_list" :key="second_index" ><router-link  :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }" >
												{{ second_item.category_name }}
											</router-link></li>
						        
						    </ul>
						
						
						
						
						
						</li>
														                                                   
														                                                </ul>
														                                            </li>
																									
																									
																									
			                                           
			                                            
			                                            
			                                            
			                                            <li v-for="(nav_item, nav_index) in navList"
						:key="nav_index"
						:class="nav_item.url == navSelect ? 'router-link-active' : ''"
						@click="navUrl(nav_item.url, nav_item.is_blank)"  style="    d
    color: #222; font-size: 16px;
    font-weight: 500; font-family: 'sukhumvittadmai2';
    " > {{ nav_item.nav_title }} </li>
			                                            
			                                        </ul>
			                                    </nav>
			                                </div>
			                              </div>
			                            </div>
			                            
			                        </div>
			                    </div>
			                </div>
	</div>
</template>

<script>
import NsHeaderTop from './NsHeaderTop';
import NsHeaderMid from './NsHeaderMid';
import { tree , categoryConfig} from '@/api/goods/goodscategory';
import { navList } from '@/api/website';
import {
	mapGetters
} from "vuex"
export default {
	props: {
		forceExpand: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapGetters(['is_show'])


	},
	data() {
		return {
			isShopHover: false,
			isIndex: false,
			thisRoute: '',
			goodsCategoryTree: [],
			categoryConfig:{},
			selectedCategory: -1,
			navList: [],
			navSelect: '',
			isHide:false
		};
	},
	components: {
		NsHeaderTop,
		NsHeaderMid,
		mapGetters
	},
	beforeCreate() {},
	created() {
		this.$store.dispatch('cart/cart_count');
		this.getCategoryConfig();
		// this.getTree();
		this.nav();

		if (this.$route.path == '/' || this.$route.path == '/index'){
			this.isIndex = true;
		}

	},
	watch: {
		$route: function(curr) {
			this.initNav(curr.path);
			let judgeLength = localStorage.getItem('isAdList')
			if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
			else this.isIndex = false;

			if (curr.path == '/list') {
				this.navSelect = '';
			}
		}
	},
	methods: {
		// รับการกําหนดค่า
		getCategoryConfig(){
			categoryConfig({
				
			})
			.then(res => {
				if (res.code == 0 && res.data) {
					this.categoryConfig = res.data;
					this.getTree(res.data);
				}
			})
			.catch(err => {
				this.$notify.error({title: 'Error',message: err.message});
			});
		},
		getTree(categoryConfig) {
			tree({
				level: 3,
				template: 2
			})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.goodsCategoryTree = res.data || [];
						for(let i =0;i<this.goodsCategoryTree.length;i++) {
							if(this.goodsCategoryTree[i].child_list > 3) {
								this.isHide = true
							}
						}
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				});
		},
		nav() {
			navList({})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.navList = res.data;
						for (let i in this.navList) {
							this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
						}
						this.initNav(this.$route.path);
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				});
		},
		navUrl(url, target) {
			if (!url) return;
			if (url.indexOf('http') == -1) {
				if (target) this.$router.pushToTab({ path: url });
				else this.$router.push({ path: url });
			} else {
				if (target) window.open(url);
				else window.location.href = url;
			}
		},
		initNav(path) {
			for (let i in this.navList) {
				if (this.navList[i]['url'] == path) {
					this.navSelect = path;
					continue;
				}
			}
		},
		
		//เลื่อนเมาส์ไปที่การแสดงหมวดหมู่ผลิตภัณฑ์
		shopHover() {
			this.isShopHover = true;
		},
		//วางเมาส์เหนือหมวดหมู่สินค้าเพื่อซ่อน
		shopLeave() {
			this.isShopHover = false;
		}
	}
};
</script>

<style scoped lang="scss">
.header {
	width: 100%;
	height: 168px;
	background-color: #fff;

	.shadow {
		box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
	}
	.border {
		border: 1px solid #f5f5f5;
	}

	.nav {
		width: 1210px;
		height: 41px;
		margin: 0 auto;
		position: relative;
		.shop-sort {
			width: 210px;
			height: 41px;
			color: #fff;
			background-color: $base-color;
			float: left;
			padding: 10px 10px 11px 10px;
			box-sizing: border-box;
			a {
				font-size: 14px;
				line-height: 20px;
				float: left;
				color: #fff;
			}
			i {
				font-size: 14px;
				float: right;
			}
		}
		.shop-list-content{
			width: 210px;
			height: 500px;
			position: absolute;
			left: 0;
			top: 41px;
			background-color: #333;
			display: none;
			padding: 0;
			box-sizing: border-box;
			font-size: $ns-font-size-base;
			z-index: 10;
			color:  #FFFFFF;
			&::-webkit-scrollbar{
				display: none;
			}
			// overflow: hidden;
			.shop-list {
				width: 210px;
				height: 500px;
				overflow-y: auto;
				overflow-x: hidden;
				// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
				&::-webkit-scrollbar{
					display: none;
				}
				a:hover {
					color: $base-color;
				}
				.list-item {
					padding-left: 12px;
					box-sizing: border-box;
					// min-height:50px;
					// height: 45px;
					// line-height: 50px;
					padding:13px;
					a {
						display: flex;
						justify-content: space-between;
						align-items: center;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// width: 95%;
						// display: block;
						color:  #FFFFFF;
						>div{
							display: flex;
							align-items: center;
						}
					}
					&:hover {
						background-color: #000;
						-webkit-transition: 0.2s ease-in-out;
						-moz-transition: -webkit-transform 0.2s ease-in-out;
						-moz-transition: 0.2s ease-in-out;
						transition: 0.2s ease-in-out;
						a:hover {
								color: #FD274A;
						}
					}
					span:hover {
						color: #FD274A;
					}
					.category-img{
						width:17px;
						height: 17px;
						margin-right: 10px;
					}
					.category-name{
						overflow:hidden;
						text-overflow:ellipsis;
						white-space:nowrap;
					}
					.item-itm {
						font-size: 14px;
						line-height: 15px; 
						height: 28px;
						overflow: hidden;
						
						a{
							margin-top: 5px;
							margin-right: 10px;
							color:#BFBFBF;
							&:hover{
								color: #FD274A !important;
							}
						}
						
						&.item-itm-img{
							margin-left: 27px;
						}
					}
					.cate-part {
						display: none;
						position: absolute;
						left: 210px;
						top: 0;
						z-index: auto;
						// width: 998px;
						width: 760px;
						height: 498px;
						overflow-y: auto;
						border: 1px solid #f7f7f7;
						background-color: #fff;
						-webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						-moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
						-webkit-transition: top 0.25s ease;
						-o-transition: top 0.25s ease;
						-moz-transition: top 0.25s ease;
						transition: top 0.25s ease;
						&.show {
							display: block;
						}
						&::-webkit-scrollbar{
							display: none;
						}
					}
					.cate-part-col1 {
						float: left;
						width: 100%;
						.cate-detail-item {
							position: relative;
							min-height: 36px;
							padding-left: 20px;
							&:last-child{
								margin-bottom: 30px;
							}
							.cate-detail-tit {
								margin-top:30px;
								margin-right: 20px;
								white-space: nowrap;
								text-overflow: ellipsis;
								font-weight: 700;
								a {
									display: block;
									color: #333333;
								}
								a:hover{
									color: #FD274A;
								}
								
							}
							.cate-detail-con {
								overflow: hidden;
								padding: 6px 0 6px 17px;
								display: flex;
								flex-wrap: wrap;
								// border-top: 1px dashed #eee;
								a {
									justify-content: start;
									font-size: 12px;
									height:30px;
								    /* float: left; */
								    margin: 4px 40px 4px 0;
								    padding: 0 10px;
								    white-space: nowrap;
								    line-height: 30px;
								    color: #666;
									width: calc((100% - 120px) / 4);
									display: flex;
									box-sizing: border-box;
									margin-top: 20px;
									.cate-detail-img{
										width:30px;
										height: 30px;
										margin-right: 10px;
									}
									&:nth-child(4n+4){
										margin-right: 0;
									}
								}
								a:hover{
									color: #FD274A;
								}
							}
							&:first-child .cate-detail-con {
								border-top: none;
							}
						}
					}
			
					// .sub-class-right {
					// 	display: block;
					// 	width: 240px;
					// 	height: 439px;
					// 	float: right;
					// 	border-left: solid 1px #e6e6e6;
					// 	overflow: hidden;
					// 	.adv-promotions {
					// 		display: block;
					// 		height: 441px;
					// 		margin: -1px 0;
					// 		a {
					// 			background: #fff;
					// 			display: block;
					// 			width: 240px;
					// 			height: 146px;
					// 			border-top: solid 1px #e6e6e6;
					// 			img {
					// 				background: #d3d3d3;
					// 				width: 240px;
					// 				height: 146px;
					// 			}
					// 		}
					// 	}
					// }
				}
			}
		}
		
		.shop-list-active {
			display: block;
		}
		nav {
			width: 934px;
			height: 36px;
			float: left;
			font-size: 14px;
			ul {
				margin: 0;
				padding: 0;
				width: 100%;
				height: 100%;
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					padding: 8px 24px;
					a {
						&:hover {
							color: $base-color;
						}
					}
				}
				li:hover {
					color: $base-color;
					font-weight: bold;
				}
				.router-link-active {
					color: $base-color;
					font-weight: bold;
					border-bottom: 2px solid  #FD274A;
				}
			}
		}
	}
} /*! CSS Used from: https://themepure.net/template/topico-prev/topico/assets/css/bootstrap.min.css */
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  h4,
  h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  h4 {
    font-size: calc(1.275rem + .3vw);
  }

  @media (min-width:1200px) {
    h4 {
      font-size: 1.5rem;
    }
  }

  h6 {
    font-size: 1rem;
  }

  ul {
    padding-left: 2rem;
  }

  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ul ul {
    margin-bottom: 0;
  }

  a {
    color: #0d6efd;
    text-decoration: underline;
  }

  a:hover {
    color: #0a58ca;
  }

  img {
    vertical-align: middle;
  }

  button {
    border-radius: 0;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button,
  input,
  select {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  select {
    word-wrap: normal;
  }

  select:disabled {
    opacity: 1;
  }

  [type=button],
  [type=submit],
  button {
    -webkit-appearance: button;
  }

  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  .container-fluid {
    width: 100%;
    padding-right: var(--bs-gutter-x, 5rem);
    padding-left: var(--bs-gutter-x, 5rem);
    margin-right: auto;
    margin-left: auto;
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }

  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  @media (min-width:576px) {
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  @media (min-width:768px) {
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  @media (min-width:992px) {
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
  }

  @media (min-width:1200px) {
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
  }

  .d-flex {
    display: flex !important;
  }

  .d-none {
    display: none !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  @media (min-width:576px) {
    .d-sm-block {
      display: block !important;
    }

    .justify-content-sm-between {
      justify-content: space-between !important;
    }
  }

  @media (min-width:768px) {
    .d-md-block {
      display: block !important;
    }

    .d-md-flex {
      display: flex !important;
    }

    .d-md-none {
      display: none !important;
    }

    .justify-content-md-end {
      justify-content: flex-end !important;
    }
  }

  @media (min-width:992px) {
    .d-lg-block {
      display: block !important;
    }
  }

  @media (min-width:1200px) {
    .d-xl-block {
      display: block !important;
    }

    .d-xl-none {
      display: none !important;
    }
  }

  /*! CSS Used from: https://themepure.net/template/topico-prev/topico/assets/css/nice-select.css */
  .nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #e8e8e8;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 42px;
    line-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 30px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
  }

  .nice-select:hover {
    border-color: #dbdbdb;
  }

  .nice-select:active,
  .nice-select:focus {
    border-color: #999;
  }

  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 5px;
  }

  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
    -ms-transform: scale(0.75) translateY(-21px);
    transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9;
  }

  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .nice-select .option.selected {
    font-weight: bold;
  }

  /*! CSS Used from: https://themepure.net/template/topico-prev/topico/assets/css/magnific-popup.css */
  button::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  /*! CSS Used from: https://themepure.net/template/topico-prev/topico/assets/css/fontAwesome5Pro.css */
  .fal,
  .far,
  .fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  .fa-angle-down:before {
    content: "\f107";
  }

  .fa-bars:before {
    content: "\f0c9";
  }

  .fa-times:before {
    content: "\f00d";
  }

  .fa-trash-alt:before {
    content: "\f2ed";
  }

  .fal {
    font-weight: 300;
  }

  .fal,
  .far {
    font-family: "Font Awesome 5 Pro";
  }

  .far {
    font-weight: 400;
  }

  .fas {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
  }

  /*! CSS Used from: https://themepure.net/template/topico-prev/topico/assets/css/default.css */
  .mb-10 {
    margin-bottom: 10px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  /*! CSS Used from: https://themepure.net/template/topico-prev/topico/assets/css/style.css */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  input {
    outline: none;
  }

  a,
  button,
  span,
  i,
  input,
  select,
  li,
  img,
  *::after,
  *::before,
  h4,
  h6 {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  a:focus {
    text-decoration: none;
    outline: none;
  }

  a:focus,
  a:hover {
    color: inherit;
    text-decoration: none;
  }

  a,
  button {
    color: inherit;
    outline: none;
    border: none;
  }

  button {
    background: transparent;
  }

  button:hover,
  select:hover {
    cursor: pointer;
  }

  h4,
  h6 {
    font-family: "Rubik", sans-serif;
    color: #222;
    margin-top: 0px;
    font-weight: 500;
    line-height: 1.2;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }

  h4 {
    font-size: 20px;
  }

  h6 {
    font-size: 14px;
  }

  ul {
    margin: 0px;
    padding: 0px;
  }

  li {
    list-style: none;
  }

  *::-moz-selection {
    background: #b1132a;
    color: #ffffff;
    text-shadow: none;
  }

  ::-moz-selection {
    background: #b1132a;
    color: #ffffff;
    text-shadow: none;
  }

  ::selection {
    background: #b1132a;
    color: #ffffff;
    text-shadow: none;
  }

  *::-moz-placeholder {
    color: #222;
    font-size: 14px;
    opacity: 1;
  }

  *::placeholder {
    color: #222;
    font-size: 14px;
    opacity: 1;
  }

  .f-left {
    float: left;
  }

  .f-right {
    float: right;
  }

  .p-relative {
    position: relative;
  }

  .t-y-btn {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    background-color: #b1132a;
    height: 45px;
    line-height: 42px;
    padding: 0 50px;
    color: #ffffff;
    border-radius: 30px;
    text-transform: initial;
    z-index: 1;
    text-transform: uppercase;
    border: 2px solid transparent;
    text-align: center;
  }

  .t-y-btn:hover {
    border-color: #b1132a;
    color: #222;
    background: #ffffff;
  }

  .t-y-btn-border {
    background: #ffffff;
    color: #222;
    border-color: #b1132a;
  }

  .t-y-btn-border:hover {
    background: #b1132a;
    color: #ffffff;
  }

  .main-menu ul li {
    display: inline-block;
    margin-right: 58px;
    position: relative;
	 cursor: pointer;
  }
 .main-menu ul li.pointer  {
    cursor: pointer;
  }

  .main-menu ul li:hover>a {
    color: #b1132a;
  }

  .main-menu ul li:hover ul.submenu {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }

  .main-menu ul li a {
    display: block;
    font-size: 14px;
    color: #222;
    font-weight: 500;
    text-transform: uppercase;
    padding: 9.5px 0;
  }

  .main-menu ul li ul.submenu {
    position: absolute;
    top: 120%;
    left: 0;
	right: -72px;
    min-width: 200px;
    background: #ffffff;
    -webkit-box-shadow: 0px 15px 30px 0px rgba(0, 3, 143, 0.1);
    -moz-box-shadow: 0px 15px 30px 0px rgba(0, 3, 143, 0.1);
    box-shadow: 0px 15px 30px 0px rgba(0, 3, 143, 0.1);
    border: 1px solid #b0c6c64d;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.1s ease-out 0.1s;
    -moz-transition: all 0.1s ease-out 0.1s;
    -ms-transition: all 0.1s ease-out 0.1s;
    -o-transition: all 0.1s ease-out 0.1s;
    transition: all 0.1s ease-out 0.1s;
  margin-left: -68px;
       z-index: 9999;
  }

  .main-menu ul li ul.submenu li {
    display: block;
    margin-right: 0;
  }

  .main-menu ul li ul.submenu li a {
    position: relative;
    padding: 9px 25px;
    font-size: 13px;
    text-transform: capitalize;
    border-bottom: 1px solid #b0c6c64d;
    color: #222;
  }

  .main-menu ul li ul.submenu li a:hover {
    color: #b1132a;
  }

  .main-menu ul li ul.submenu li a i {
    position: absolute;
    top: 34%;
    right: 0;
    -webkit-transform: rotate(-90deg) translateY(-50%);
    -moz-transform: rotate(-90deg) translateY(-50%);
    -ms-transform: rotate(-90deg) translateY(-50%);
    transform: rotate(-90deg) translateY(-50%);
  }

  .main-menu ul li ul.submenu li:last-child a {
    border-bottom: none;
  }

  .main-menu ul li ul.submenu li ul.submenu {
    left: 120%;
    top: -1%;
    visibility: hidden;
    opacity: 0;
  }

  .main-menu ul li ul.submenu li:hover ul.submenu {
    visibility: visible;
    opacity: 1;
    left: 100%;    margin-left: 0px;
  }

  .side-menu-btn {
    background: transparent;
    font-size: 18px;
    color: #222;
  }

  .side-menu-btn:hover {
    color: #b1132a;
  }

  


  .header__bottom-border {
        border-bottom: 1px solid #e6e6e6;
  }

  .header__action ul li {
    display: inline-block;
    line-height: 45px;
    position: relative;
    padding-right: 20px;
    margin-right: 20px;
  }

  .header__action ul li:last-child {
    margin-right: 0;
  }

  .header__action ul li::after {
    position: absolute;
    content: "";
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    width: 1px;
    height: 13px;
    background: #ebebeb;
  }

  .header__action ul li a {
    font-size: 13px;
    display: block;
  }

  @media (max-width: 575px) {
    .header__action ul li a {
      padding-right: 10px;
      margin-right: 10px;
    }
  }

  .header__action ul li a:hover {
    color: #b1132a;
  }

  .header__action ul li:last-child a {
    margin-right: 0;
    padding-right: 0;
  }

  .header__action ul li:last-child a::after {
    display: none;
  }

  .header__info {
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .header__info-left {
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header__info-left {
      margin-bottom: 20px;
    }
  }

  .header__info-right {
    position: relative;
  }

  .header__search {
    width: 70%;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .header__search {
      width: 100%;
    }
  }

  @media (max-width: 575px) {
    .header__search {
      width: 100%;
    }
  }

  .header__search form {
    position: relative;
  }

  .header__search-box {
    width: 100%;
    position: relative;
  }

  .header__search-box::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 160px;
    height: 45%;
    width: 1px;
    background: #e1e1e1;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @media only screen and (min-width: 576px) and (max-width: 767px),
  (max-width: 575px) {
    .header__search-box::before {
      display: none;
    }
  }

  .header__search-box input {
    width: 100%;
    height: 50px;
    line-height: 50px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    border: 2px solid #b1132a;
    outline: none;
    padding: 0 15px;
    padding-left: 180px;
    padding-right: 190px;
  }

  @media (max-width: 575px) {
    .header__search-box input {
      padding-left: 15px;
    }
  }

  .header__search-box input::placeholder {
    color: #e1e1e1;
    font-size: 12px;
  }

  .header__search-box button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    height: 50px;
    color: #222;
    display: block;
    line-height: 50px;
    padding: 0 65px;
    font-size: 14px;
    font-weight: 400;
    background: #b1132a;
    border-radius: 0 30px 30px 0;
    text-transform: capitalize;
  }

  .header__search-cat {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }

  @media (max-width: 575px) {
    .header__search-cat {
      display: none;
    }
  }

  .header__search-cat .nice-select {
    border: none;
    padding-left: 0;
    color: #6d6d6d;
  }

  .header__search-cat .nice-select::after {
    border-width: 1px;
    border-color: #222;
    height: 8px;
    width: 8px;
    margin-top: -7px;
  }

  .header__search-cat .nice-select .list {
    min-width: 220px;
    height: 250px;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .header__search-cat .nice-select .list .option {
    padding: 10px 6px;
    line-height: 1.5;
  }

  .header__search-cat .nice-select .list .option:hover,
  .header__search-cat .nice-select .list .option.selected {
    color: #ffffff;
    background: #b1132a;
  }

  .header__currency {
    position: relative;
    padding-right: 10px;
    margin-right: 20px;
  }

  .header__currency::before {
    position: absolute;
    content: "";
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background: #e1e1e1;
    height: 15px;
    width: 1px;
  }

  .header__currency .nice-select {
    border: none;
    padding-left: 0;
    color: #6d6d6d;
    font-size: 13px;
  }

  .header__currency .nice-select::after {
    border-width: 1px;
    border-color: #222;
    height: 6px;
    width: 6px;
    margin-top: -5px;
  }

  .header__currency .nice-select .list {
    min-width: 220px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    left: auto;
    right: 10%;
  }

  .header__currency .nice-select .list .option {
    padding: 10px 15px;
    line-height: 1.5;
  }

  .header__currency .nice-select .list .option:hover,
  .header__currency .nice-select .list .option.selected {
    color: #ffffff;
    background: #333;
  }

  .header__lang {
    position: relative;
  }

  .header__lang .nice-select {
    border: none;
    padding-left: 0;
    color: #6d6d6d;
    font-size: 13px;
  }

  .header__lang .nice-select::after {
    border-width: 1px;
    border-color: #222;
    height: 6px;
    width: 6px;
    margin-top: -5px;
  }

  .header__lang .nice-select .list {
    left: auto;
    right: 0;
    min-width: 220px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }

  .header__lang .nice-select .list .option {
    padding: 10px 15px;
    line-height: 1.5;
  }

  .header__lang .nice-select .list .option:hover,
  .header__lang .nice-select .list .option.selected {
    color: #ffffff;
    background: #333;
  }

  .cart__toggle {
    position: relative;
    width: 50px;
    height: 50px;
    display: inline-block;
    border: 2px solid #ebebeb;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin-right: 25px;
  }

  .cart__toggle::after {
    content: "\f290";
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    color: #222;
    position: absolute;
    top: 0;
    margin-right: 2px;
    line-height: 46px;
    right: 12px;
  }

  .cart__toggle:hover {
    background: #b1132a;
    color: #ffffff;
    border-color: #b1132a;
  }

  .cart__toggle:hover::after {
    color: #ffffff;
  }

  .cart__toggle:hover .cart__total-item {
    background: #222;
    color: #ffffff;
  }

  .cart__mini {
    position: absolute;
    top: 120%;
    right: 0;
    width: 350px;
    background: #ffffff;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    z-index: 11;
    border-top: 2px solid #b1132a;
    padding: 35px 15px;
    padding-top: 27px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -ms-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
  }

  .cart__close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .cart__close-btn {
    font-size: 16px;
    color: #222;
    background: transparent;
  }

  .cart__close-btn:hover {
    color: #b1132a;
  }

  .cart__title {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
  }

  .cart__title h4 {
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 0;
  }

  .cart__title span {
    font-size: 12px;
  }

  .cart__total-item {
    min-width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    position: absolute;
    top: -2px;
    left: 35px;
    color: #ffffff;
    background: #b1132a;
    border-radius: 100%;
    font-size: 10px;
  }

  .cart__content span {
    display: block;
  }

  .cart__item {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #ebebeb;
  }

  .cart__price span {
    color: #b1132a;
  }

  .cart__sub {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .cart__sub h6 {
    margin-bottom: 0;
  }

  .cart__sub-total {
    font-size: 14px;
    color: #222;
    font-weight: 500;
    color: #b1132a;
  }

  .cart__thumb {
    margin-right: 15px;
  }

  .cart__thumb img {
    width: 70px;
    height: 70px;
  }

  /*! CSS Used fontfaces */
  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300;
    font-display: block;
    src: url(https://overmine.org/fa-light-300.eot);
    src: url(https://overmine.org/fa-light-300.eot#iefix) format("embedded-opentype"), url(https://overmine.org/fa-light-300.woff2) format("woff2"), url(https://overmine.org/fa-light-300.woff) format("woff"), url(https://overmine.org/fa-light-300.ttf) format("truetype"), url(https://overmine.org/fa-light-300.svg#fontawesome) format("svg");
  }

  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(https://overmine.org/fa-regular-400.eot);
    src: url(https://overmine.org/fa-regular-400.eot#iefix) format("embedded-opentype"), url(https://overmine.org/fa-regular-400.woff2) format("woff2"), url(https://overmine.org/fa-regular-400.woff) format("woff"), url(https://overmine.org/fa-regular-400.ttf) format("truetype"), url(https://overmine.org/fa-regular-400.svg#fontawesome) format("svg");
  }

  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url(https://overmine.org/fa-solid-900.eot);
    src: url(https://overmine.org/fa-solid-900.eot#iefix) format("embedded-opentype"), url(https://overmine.org/fa-solid-900.woff2) format("woff2"), url(https://overmine.org/fa-solid-900.woff) format("woff"), url(https://overmine.org/fa-solid-900.ttf) format("truetype"), url(https://overmine.org/fa-solid-900.svg#fontawesome) format("svg");
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nMrXyi0A.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nFrXyi0A.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nDrXyi0A.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
  }

  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nPrXyi0A.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
.container {
 max-width: 1436px !important;
}
  @font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/rubik/v21/iJWKBXyIfDnIV7nBrXw.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
.col-xl-9 {
    flex: 0 0 auto;
    width: 100%;
}.align-items-center {
    align-items: center !important;
}.main-menu ul li {

    cursor: pointer;
}
</style>
