<template>
    <el-container style="   background: url(https://wallpaper.dog/large/10892393.jpg7);
   background-size: cover;  ">
        <!-- หัว -->
        <el-header height="auto" class="header"><member-header /></el-header>
		
	
		
        <el-main class="content" style="   background: url(https://img.freepik.com/free-vector/copy-space-wavy-white-background-layers_23-2148845469.jpg?w=2000);
    background-size: cover;   ">
            <transition name="slide" ><router-view /></transition>

            <!-- คอลัมน์ด้านขวา -->
            <ns-aside />
        </el-main>
        <!-- ก้น -->
        <el-footer><ns-footer /></el-footer>
    </el-container>
</template>
<script>
    import MemberHeader from "./components/MemberHeader"
    import NsHeader from "./components/NsHeader"
    import NsAside from "./components/NsAside"
    import NsFooter from "./components/NsFooter"

    export default {
        created() {},
        data: () => {
            return {}
        },
        mounted() {},
        computed: {},
        watch: {},
        components: {
            MemberHeader,
            NsAside,
            NsFooter
        }
    }
</script>
<style lang="scss" scoped>
    .header {
        padding: 0;
    }
    .content {
        max-width: $width;
        margin: 0 auto;
        padding: 0;
    }
    .el-footer { margin-top: 51px;
        padding: 0;
        height: auto !important;
        background-color: #fff;
        padding-top: 0px;
    }

    .el-main {
        border-top: none;
    }.content {
    max-width: 1374px;
    margin: 0 auto;
    padding: 0;
}

.el-main{
    border-top: solid 1px #e6e6e600;
  
}.member-index .member-top .collection .title {
    padding: 10px 0;
    display: inline-block;
    border-bottom: 1px solid #fe2f2f00;
}.member-index .member-top .collection .xian {
    height: 1px;
    width: 230px;
    margin-left: -20px;
    background: #f1f1f1;
}.member-index .member-top .collection {
    background: #ffffff;
    margin-left: 20px;
    width: 232px;
    border-radius: 12px !important;
    border: 1px solid #e9e9e94f;
    padding-left: 20px;
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/j/j.v4.85.min.css?v=1626429966 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
ul li{list-style:none;}
a{color:#f73173;text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/head_foot.min.css?v=1664360752 */
ul,li{list-style:none;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/index.min.css?v=1654680463 ; media=screen */
@media screen{
div,ul,li{margin:0;padding:0;}
img{border:0;}
li{list-style:none;}
a{text-decoration:none;}
a:hover{text-decoration:underline;}
}
/*! CSS Used from: https://s2.konvy.com/static/min/css/utils/css/ky.min.css?v=1664420402 */
*{padding:0;margin:0;box-sizing:content-box;}
li,ul{list-style:none;}
img{border:none;}
a:hover,a{text-decoration:none;}
a{color:#333;}
.ky-w100{width:100%;}
/*! CSS Used from: https://s2.konvy.com/static/css/admin/swiper.min.css */
.swiper-wrapper{position:relative;width:100%;height:100%;z-index:1;display:-webkit-box;display:-moz-box;display:-ms-flexbox;display:-webkit-flex;display:flex;-webkit-transition-property:-webkit-transform;-moz-transition-property:-moz-transform;-o-transition-property:-o-transform;-ms-transition-property:-ms-transform;transition-property:transform;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;}
.swiper-wrapper{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-o-transform:translate(0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0);}
.swiper-slide{-webkit-flex-shrink:0;-ms-flex:0 0 auto;flex-shrink:0;width:100%;height:100%;position:relative;}
.swiper-pagination{position:absolute;text-align:center;-webkit-transition:.3s;-moz-transition:.3s;-o-transition:.3s;transition:.3s;-webkit-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0);z-index:10;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/admin/adminbrand_flagship.min.css?v=1665338270 */
*{margin:0;padding:0;-webkit-text-size-adjust:none;}
li,ul{list-style:none;}
a{text-decoration:none;color:#333;}
img{vertical-align:middle;}
.moduleWarp{overflow:hidden;}
.swiper-banner{height:360px;position:relative;}
.swiper-banner .swiper-wrapper{height:100%;}
.swiper-banner .swiper-slide{height:100%;background-repeat:no-repeat;background-position:center;background-size:cover;}
.swiper-banner{overflow:hidden;}
.swiper-slide a{width:100%;height:100%;display:block;}
.banner_index{z-index:0;}
.banner_index{z-index:0;}
.module_banner>li{position:relative;}
/*! CSS Used from: https://s2.konvy.com/static/min/css/css/web/promo_new.min.css?v=1661745912 */
.moduleWarp{display:none;}
</style>
