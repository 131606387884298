import http from "../utils/http"

/**
 * รับรหัส QR ของ WeChat
 */
export function logincode(params) {
    return http({
        url: "/wechat/api/wechat/logincode",
        data: params
    })
}

/**
 * ตรวจหาว่ามีการสแกนรหัสหรือไม่
 */
export function checklogin(params) {
    return http({
        url: "/api/login/checklogin",
        data: params
    })
}
/**
 * การเข้าสู่ระบบ WeChat จะผูกไว้กับหมายเลขโทรศัพท์มือถือ
 */
export function wechatLogin(params) {
    return http({
        url: "/api/login/wechatLogin",
        data: params
    })
}

