import Vue from "vue"
import VueRouter from "vue-router"
import Lang from "../utils/lang.js"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import indexRoutes from "./modules/index"
import memberRoutes from "./modules/member"
import authRoutes from "./modules/auth"
import {
	getToken
} from "../utils/auth"
import store from "../store"

// มีการรายงานข้อผิดพลาดเมื่อหน้ากากกระโดดเข้าหาตัวเอง
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	if (location && typeof location == "string" && location.indexOf("http") != -1) return window.location.open(location,
		"_self")

	return originalPush.call(this, location).catch(err => err)
}

const originalResolve = VueRouter.prototype.resolve
VueRouter.prototype.pushToTab = function pushToTab(location) {
	if (!location) return;
	if (location && typeof location == "string" && location.indexOf("http") != -1) return window.open(location, "_blank")

	const {
		href
	} = originalResolve.call(this, location)
	window.open(href, "_blank")
}

Vue.use(VueRouter)

/**
 * metaการแยกวิเคราะห์พารามิเตอร์
 * module: เป็นของโมดูล，ขณะนี้ใช้เพื่อค้นหาชุดภาษา
 * module: โมดูลที่เป็นของเมนู
 * menu: เมนูที่เป็นของ，ใช้เพื่อกําหนดว่าเมนูสามระดับถูกเน้นหรือไม่，เช่นรายการเมนู、เพิ่มเมนู、เมนูแก้ไขเป็นทั้ง'menu'，รายชื่อผู้ใช้、เพิ่มผู้ใช้、การแก้ไขผู้ใช้คือ'user'，และอื่น ๆ
 */
const mainRouters = [
	indexRoutes,
	authRoutes,
	memberRoutes,
	{
		path: "/closed",
		name: "closed",
		meta: {
			module: "index"
		},
		component: () => import("@/views/index/closed.vue")
	},
	{
		path: "*",
		name: "error",
		meta: {
			module: "index"
		},
		component: () => import("@/views/index/error.vue")
	}
]

const router = new VueRouter({
	mode: 'history',
	base: '/web/',
	routes: mainRouters
})

// ยามเส้นทาง，ควบคุมการเข้าถึง
router.beforeEach((to, from, next) => {
	// if (store.getters.siteInfo && !store.getters.siteInfo.web_status && to.path != "/closed") {
	//     return next("/closed")
	// }

	if (to.meta.auth) {
		const token = getToken()
		if (!token) {
			return next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
		}
	}

	window.document.body.style.backgroundColor = to.meta.backgroundColor || ""

	NProgress.start()
	next()
})

router.afterEach((to, from) => {
	const title = Lang.getLangField("title", to)
	const metaTitle = store.getters.siteInfo.site_name || ""
	window.document.title = `${title} - ${metaTitle}`
	setTimeout(() => {
		if (document.getElementsByClassName("el-main").length) {
			if (to.meta.mainCss) {
				for (let field in to.meta.mainCss) {
					document.getElementsByClassName("el-main")[0].style[field] = to.meta.mainCss[field]
				}
			} else {
				document.getElementsByClassName("el-main")[0].style = ""
			}
		}
	}, 100)
	NProgress.done()
})

export default router
