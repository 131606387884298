import http from "../../utils/http"

/**
 * รายการสินค้า
 * @param {object} params
 */
export function goodsSkuPage(params) {
	return http({
		url: "/api/goodssku/page",
		data: params
	})
}

export function goodsSkuList(params) {
	return http({
		url: "/api/goodssku/lists",
		data: params
	})
}

/**
 * รายละเอียดสินค้า
 * @param {Object} params
 */
export function goodsSkuDetail(params) {
	return http({
		url: "/api/goodssku/detail",
		data: params
	})
}





/**
 * รายละเอียดสินค้า
 * @param {Object} params
 */
export function goodsSkuCategory(params) {
	return http({
		url: "/api/goodssku/getInfoForCategory",
		data: params
	})
}




/**
 * รับสิทธิ์การตรวจสอบข้อมูลผลิตภัณฑ์ใน Store
 * @param {object} params
 */
export function evaluateConfig() {
    return http({
        url: "/api/goodsevaluate/config",
        data: {},
        forceLogin: true
    })
}

/**
 * ข้อมูลผลิตภัณฑ์
 * @param { Object } params
 */
export function goodsSkuInfo(params) {
	return http({
		url: "/api/goodssku/info",
		data: params
	})
}

/**
 * ข้อมูลผลิตภัณฑ์ดีลสายฟ้าแลบ
 * @param { Object } params
 */
export function seckillGoodsInfo(params) {
	return http({
		url: "/seckill/api/seckillgoods/info",
		data: params
	})
}

/**
 * ข้อมูลผลิตภัณฑ์
 * @param { Object } params
 */
export function goodsQrcode(params) {
	return http({
		url: "/api/goodssku/goodsqrcode",
		data: params
	})
}

/**
 * รับข้อมูลส่วนลดทั้งหมด
 * @param {Object} params
 */
export function manjian(params) {
	return http({
		url: "/manjian/api/manjian/info",
		data: params
	})
}

/**
 * รับบริการหลังการขาย
 * @param {Object} params
 */
export function aftersale(params) {
	return http({
		url: "/api/goods/aftersale",
		data: params
	})
}

/**
 * อัปเดตการคลิกผลิตภัณฑ์
 * @param {Object} params
 */
export function modifyClicks(params) {
	return http({
		url: "/api/goods/modifyclicks",
		data: params
	})
}

/**
 * เพิ่มรอยเท้าผลิตภัณฑ์
 * @param {Object} params
 */
export function addGoodsbrowse(params) {
	return http({
		url: "/api/goodsbrowse/add",
		data: params
	})
}

/**
 * รายการคําแนะนําผลิตภัณฑ์
 * @param {object} params
 */
export function goodsRecommend(params) {
	return http({
		url: "/api/goodssku/recommend",
		data: params
	})
}

/**
 * รายชื่อแบรนด์
 */
// export function brandList(params) {
// 	return http({
// 		url: "/api/goodsbrand/page",
// 		data: params
// 	})
// }
