<template>
    <el-container>
        <!-- หัว -->
        <el-header class="ns-login-header">
            <div class="header-in">
                <!-- <ns-header-mid /> -->
                <el-row>
                    <el-col :span="6">
                        <router-link to="/" class="logo-wrap"><img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" /></router-link>
                    </el-col>
                </el-row>
            </div>
        </el-header>
        <el-main>
            <transition name="slide"><router-view /></transition
        ></el-main>
		
		   <el-footer style=" padding: 0 0px !important;"><ns-footer /></el-footer>
        <!-- ก้น -->
        <!-- <el-footer class="login-footer"><copy-right /></el-footer> -->
    </el-container>
</template>
<script>
    import CopyRight from "./components/CopyRight"
    import NsHeaderMid from "./components/NsHeaderMid"
	   import NsFooter from "./components/NsFooter"
    import { mapGetters } from "vuex"

    export default {
        name: "Layout",
        components: {
            CopyRight,
			 NsFooter,
            NsHeaderMid
        },
        created() {
            this.$store.dispatch("site/siteInfo")
        },
        mounted() {},
        computed: {
            ...mapGetters(["siteInfo"])
        },

        watch: {},
        methods: {}
    }
</script>
<style lang="scss">
    .ns-login-header {
        height: 89px !important;
    }
.kIMokt {
    padding-bottom: 20px;
   
}
    .header-in {
        width: $width;
        height: 89px;
        margin: 20px auto 0;
        .logo-wrap {
            width: 240px;
            height: 68px;
            display: block;
            line-height: 68px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
	.kIMokt {
	    display: none !important;
	 
	}
	.login-footer .footer-bottom p .footer-link, .login-footer .footer-bottom p .el-link.el-link--default {
		color: #303133;
	}
	
	.login-footer .footer-bottom p .footer-link:hover {
		color: $base-color;
	}.el-footer {
   padding: 0 0px !important;
}.el-main {
    border-top: solid 1px #e6e6e6;
    overflow: initial;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}.footer .footer-top {
    padding-top: -44px !important;
}.footer .footer-top{
    background-color: #fff;
    padding-top: 0px !important;
}
</style>
