<template>
	<header class="ts-header has-sticky">
	  <div class="header-container">
	    <div class="header-template">
	      <div id="undefined-sticky-wrapper" class="sticky-wrapper" style="height: 89px;">
	        <div class="header-sticky" style="">
	          <div class="header-middle logo-center">
	            <div class="container">
	              <div class="header-left">
	                <div class="ts-search-by-category">
	                  <div style="    min-width: 334px;
    max-width: 100%;">
	                    <div class="search-table">
	                      <div class="search-field search-content">
							  
							  
							
							  
							  
							  
							  
							  
	                        <input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" style="    background-color: rgb(242, 242, 242);
    border-color: rgb(242, 242, 242);
    padding: 6px 60px 6px 20px;
    margin: 0;
    width: 100%;
    max-width: 100%;
    font-size: 100%; font-family: 'Prompt'; ">
	                
	                      </div>
	                      <div class="search-button">
	                        <input type="submit"   @click="search">
	                      </div>
						  
	                    </div>
	                  </div>
	                </div>
	              </div>
	              <div class="logo-wrapper">
	                <div class="logo">
						<router-link to="/" class="logo-wrap" style="    font-size: 0px;">
	                  <a >
	                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)"  alt="" title="" class="normal-logo">
	                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)"  alt="" title="" class="mobile-logo">
	                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)"  alt="" title="" class="sticky-logo">
	                  </a></router-link>
	                </div>
	              </div>
	              <div class="header-right">
	                <div class="my-account-wrapper hidden-phone">
	                  <div class="ts-tiny-account-wrapper">
						  
						  
					
						  
						  
						  <router-link v-if="!logined" to="/login">
	                    <div class="account-control">
	                      <a class="my-account"  title="My Account">
	                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	                          <path d="M10 10.8166C12.6372 10.8166 14.775 8.67876 14.775 6.0416C14.775 3.40444 12.6372 1.2666 10 1.2666C7.36285 1.2666 5.22501 3.40444 5.22501 6.0416C5.22501 8.67876 7.36285 10.8166 10 10.8166Z" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                          <path d="M1.25 19.5668L1.55833 17.8585C1.92258 15.8856 2.96645 14.1025 4.50859 12.8192C6.05074 11.5359 7.99371 10.8333 10 10.8335V10.8335C12.0087 10.834 13.9536 11.5389 15.496 12.8256C17.0385 14.1123 18.0809 15.8992 18.4417 17.8752L18.75 19.5835" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                        </svg>
	                      </a>
	                      
	                    </div>
						</router-link>
						
						
						<router-link v-else to="/member/index">
						<div class="account-control">
						  <a class="my-account"  title="My Account">
						    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						      <path d="M10 10.8166C12.6372 10.8166 14.775 8.67876 14.775 6.0416C14.775 3.40444 12.6372 1.2666 10 1.2666C7.36285 1.2666 5.22501 3.40444 5.22501 6.0416C5.22501 8.67876 7.36285 10.8166 10 10.8166Z" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
						      <path d="M1.25 19.5668L1.55833 17.8585C1.92258 15.8856 2.96645 14.1025 4.50859 12.8192C6.05074 11.5359 7.99371 10.8333 10 10.8335V10.8335C12.0087 10.834 13.9536 11.5389 15.496 12.8256C17.0385 14.1123 18.0809 15.8992 18.4417 17.8752L18.75 19.5835" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
						    </svg>
						  </a>
						  
						</div>
						</router-link>
						
						
						
						
						
						
						
	                  </div>
	                </div>
	                <div class="my-wishlist-wrapper hidden-phone">
	                  <a title="Wishlist" class="tini-wishlist">
	                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	                      <path d="M2.84165 10.8335L2.56665 10.5585C2.14697 10.1405 1.81374 9.64387 1.58598 9.09705C1.35822 8.55022 1.2404 7.96389 1.23924 7.37153C1.23689 6.1752 1.70988 5.02695 2.55415 4.17936C3.39843 3.33177 4.54482 2.85428 5.74115 2.85194C6.93748 2.84959 8.08574 3.32259 8.93332 4.16686L9.99999 5.25019L11.0667 4.16686C11.4808 3.73401 11.9772 3.38822 12.5268 3.14974C13.0763 2.91127 13.6679 2.78493 14.267 2.77812C14.866 2.77132 15.4603 2.88418 16.0152 3.1101C16.57 3.33602 17.0741 3.67045 17.498 4.09377C17.9219 4.51709 18.257 5.02078 18.4836 5.57531C18.7102 6.12984 18.8239 6.72405 18.8179 7.32308C18.8118 7.9221 18.6863 8.5139 18.4485 9.06376C18.2108 9.61362 17.8656 10.1105 17.4333 10.5252L17.1583 10.8002L9.99999 17.9752L5.05832 13.0335L2.84165 10.8335Z" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                    </svg>
	                    <span class="count-number">0</span>
	                  </a>
	                </div>
	                <div class="shopping-cart-wrapper hidden-phone">
	                  <div class="ts-tiny-cart-wrapper">
	                    <div class="cart-icon">
	                      <a class="cart-control" title="View your shopping cart">
	                        <span class="ic-cart">
	                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	                            <path d="M6.00834 13.1834L18.7333 10.8V3.63336H3.45834" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                            <path d="M0.43335 1.25H2.82502L6.00002 13.1833L4.61668 15.2583C4.48429 15.4615 4.41472 15.6992 4.41668 15.9417V15.9417C4.41668 16.2643 4.54487 16.5738 4.77304 16.802C5.00121 17.0302 5.31067 17.1583 5.63335 17.1583H15.55" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                            <path d="M15.55 18.75C15.9872 18.75 16.3417 18.3956 16.3417 17.9584C16.3417 17.5211 15.9872 17.1667 15.55 17.1667C15.1128 17.1667 14.7583 17.5211 14.7583 17.9584C14.7583 18.3956 15.1128 18.75 15.55 18.75Z" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                            <path d="M7.59165 18.75C8.02888 18.75 8.38332 18.3956 8.38332 17.9584C8.38332 17.5211 8.02888 17.1667 7.59165 17.1667C7.15443 17.1667 6.79999 17.5211 6.79999 17.9584C6.79999 18.3956 7.15443 18.75 7.59165 18.75Z" stroke="#191919" stroke-width="1.5" stroke-miterlimit="10"></path>
	                          </svg>
	                        </span>
	                        <span class="cart-number"  v-if="cartList.length" >{{cartList.length}}</span>
							   <span class="cart-number"  v-else >0</span>
	                      </a>
	                    </div>
	                   
					   <div class="cart-dropdown-form dropdown-container woocommerce">
					   	
					   	
					   	
					   	
					   	
					   	
					   	<div class="form-content" v-if="cartList.length">
					   														<h3 class="theme-title">Shopping Cart <span>{{ cartList.length }}</span></h3>
					   								<div class="cart-wrapper">
					   									<div class="cart-content">
					   										<ul class="cart_list">
					   																						<li class="woocommerce-mini-cart-item"  v-for="(item, index) in cartList" :key="index">
					   													<a class="thumbnail" href="https://demo.theme-sky.com/babycare/shop/diapers-size-4-172-count-disposable-baby-diapers/">
					   														<img width="350" height="350" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" :alt="item.sku_name" >												
					   														
					   														</a>
					   													<div class="cart-item-wrapper">	
					   														<h3 class="product-name">
					   															<a href="https://demo.theme-sky.com/babycare/shop/diapers-size-4-172-count-disposable-baby-diapers/">
					   																{{ item.sku_name }}														</a>
					   														</h3>
					   														
					   														<span class="price"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">฿</span>{{ item.discount_price }}x{{ item.num }}</bdi></span></span>
					   														
					   															 <el-input-number size="small" v-model="item.num"></el-input-number>
					   		<div class="subtotal"><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">฿</span>{{ item.discount_price *item.num }}</bdi></span></div>													
					   														<a  class="remove remove_from_cart_button" aria-label="Remove this item" data-cart_item_key="7a614fd06c325499f1680b9896beedeb" @click="deleteCart(index)">×</a>												</div>
					   												</li>
					   											
					   																				
					   											
					   																				</ul>
					   										<div class="dropdown-footer">
					   											<div class="total"><span class="total-title primary-text">Subtotal : </span><span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">฿</span>{{ cartTotalPrice }}</bdi></span></div>
					   											
					   											
					   											<a @click="$router.push('/cart')"class="button checkout-button">Checkout</a>
					   										</div>
					   									</div>
					   								</div>
					   												</div>
					   												
					   												
					   												
					   												
					   						<div class="form-content" v-else>
					   							
					   							
					   							
					   							<label>
					   								<svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
					   									<path d="M150 0H0V150H150V0Z" fill="white"></path>
					   									<path d="M34.5824 74.3272L33.4081 68.3582C32.1926 62.179 36.9225 56.428 43.2201 56.428H131.802C138.025 56.428 142.737 62.0523 141.647 68.1798L130.534 130.633C129.685 135.406 125.536 138.882 120.689 138.882H56.6221C51.9655 138.882 47.9253 135.668 46.8782 131.13L45.1458 123.623" stroke="#808080" stroke-width="3" stroke-linecap="round"></path>
					   									<path d="M83.5444 17.835C84.4678 16.4594 84.1013 14.5956 82.7257 13.6721C81.35 12.7486 79.4862 13.1152 78.5628 14.4908L47.3503 60.9858C46.4268 62.3614 46.7934 64.2252 48.169 65.1487C49.5446 66.0721 51.4084 65.7056 52.3319 64.33L83.5444 17.835Z" fill="#808080"></path>
					   									<path d="M122.755 64.0173C124.189 64.8469 126.024 64.3569 126.854 62.9227C127.683 61.4885 127.193 59.6533 125.759 58.8237L87.6729 36.7911C86.2387 35.9614 84.4035 36.4515 83.5739 37.8857C82.7442 39.3198 83.2343 41.155 84.6684 41.9847L122.755 64.0173Z" fill="#808080"></path>
					   									<path d="M34.9955 126.991C49.3524 126.991 60.991 115.352 60.991 100.995C60.991 86.6386 49.3524 75 34.9955 75C20.6386 75 9 86.6386 9 100.995C9 115.352 20.6386 126.991 34.9955 126.991Z" stroke="#808080" stroke-width="2" stroke-linejoin="round" stroke-dasharray="5 5"></path>
					   									<path d="M30.7 100.2C30.7 99.3867 30.78 98.64 30.94 97.96C31.1 97.2667 31.3333 96.6734 31.64 96.18C31.9467 95.6734 32.3133 95.2867 32.74 95.02C33.18 94.74 33.6667 94.6 34.2 94.6C34.7467 94.6 35.2333 94.74 35.66 95.02C36.0867 95.2867 36.4533 95.6734 36.76 96.18C37.0667 96.6734 37.3 97.2667 37.46 97.96C37.62 98.64 37.7 99.3867 37.7 100.2C37.7 101.013 37.62 101.767 37.46 102.46C37.3 103.14 37.0667 103.733 36.76 104.24C36.4533 104.733 36.0867 105.12 35.66 105.4C35.2333 105.667 34.7467 105.8 34.2 105.8C33.6667 105.8 33.18 105.667 32.74 105.4C32.3133 105.12 31.9467 104.733 31.64 104.24C31.3333 103.733 31.1 103.14 30.94 102.46C30.78 101.767 30.7 101.013 30.7 100.2ZM29 100.2C29 101.6 29.22 102.84 29.66 103.92C30.1 105 30.7067 105.853 31.48 106.48C32.2667 107.093 33.1733 107.4 34.2 107.4C35.2267 107.4 36.1267 107.093 36.9 106.48C37.6867 105.853 38.3 105 38.74 103.92C39.18 102.84 39.4 101.6 39.4 100.2C39.4 98.8 39.18 97.56 38.74 96.48C38.3 95.4 37.6867 94.5534 36.9 93.94C36.1267 93.3134 35.2267 93 34.2 93C33.1733 93 32.2667 93.3134 31.48 93.94C30.7067 94.5534 30.1 95.4 29.66 96.48C29.22 97.56 29 98.8 29 100.2Z" fill="#808080"></path>
					   									<path d="M84.6121 101.029C85.8347 99.6106 88.8961 97.625 91.3609 101.029" stroke="#808080" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path>
					   									<path d="M74.1953 92.2265C75.8158 92.2265 77.1296 90.9128 77.1296 89.2922C77.1296 87.6716 75.8158 86.3579 74.1953 86.3579C72.5747 86.3579 71.261 87.6716 71.261 89.2922C71.261 90.9128 72.5747 92.2265 74.1953 92.2265Z" fill="#808080"></path>
					   									<path d="M103.538 92.226C105.159 92.226 106.472 90.9123 106.472 89.2917C106.472 87.6711 105.159 86.3574 103.538 86.3574C101.917 86.3574 100.604 87.6711 100.604 89.2917C100.604 90.9123 101.917 92.226 103.538 92.226Z" fill="#808080"></path>
					   								</svg> <span> คุณยังไม่ได้ใส่ของ</span></label>
					   								
					   						</div>						
					   												
					   												
					   	
					   	
					   	
					   	
					   </div>
					   
					   
					   
					   
					   
					   
					   </div>
	                </div>
	                <!-- Menu Icon -->
	                <div class="icon-menu-sticky-header hidden-phone">
	                  <span class="icon">
	                    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	                      <line y1="2.39999" x2="22" y2="2.39999" stroke="black" stroke-width="1.5"></line>
	                      <line y1="9.39999" x2="22" y2="9.39999" stroke="black" stroke-width="1.5"></line>
	                      <line y1="16.4" x2="22" y2="16.4" stroke="black" stroke-width="1.5"></line>
	                    </svg>
	                  </span>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
	</header>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		apiHotSearchWords,
		apiDefaultSearchWords
	} from '@/api/pc';
	import {
		cartList as apiCartList
	} from '@/api/goods/cart';
	export default {
		props: {},
		data() {
			return {
				searchType: 'goods',
				searchTypeText: 'สินค้า',
				keyword: '',
				//num3: 1,
				hotSearchWords: [],
				defaultSearchWords: '',
				cartList: [],
				cartTotalPrice: 0
			};
		},
		components: {},
		computed: {
			...mapGetters(['cartCount', 'siteInfo', 'defaultGoodsImage', 'member']),
			logined: function() {
			    return this.member !== undefined && this.member !== "" && this.member !== {}
			}
		},
		created() {
			this.keyword = this.$route.query.keyword || '';
			if (this.$route.name == 'street') this.searchType = 'shop';
			else this.searchType = 'goods';
			this.$store.dispatch('site/siteInfo');
			this.getHotSearchWords();
			this.getDefaultSearchWords();
			this.getCartList();
		},
		watch: {
			searchType() {
				this.searchTypeText = this.searchType == 'goods' ? 'สินค้า' : 'ร้าน';
			},
			$route(curr) {
				if (this.keyword !== curr.query.keyword) {
					this.keyword = curr.query.keyword;
				}
				if (this.$route.name == 'street') this.searchType = 'shop';
				else this.searchType = 'goods';
			},
			cartCount() {
				if (this.member) this.getCartList();
			},
			member() {
				if (!this.member) {
					this.$store.commit('cart/SET_CART_COUNT', 0);
					this.cartList = [];
					this.cartTotalPrice = 0;
				}
			}
		},
		methods: {
			handleCommand(command) {
				this.searchType = command;
			},
			search() {
				if (this.searchType == 'goods') this.$router.push({
					path: '/list',
					query: {
						keyword: this.keyword ? this.keyword : this.defaultSearchWords
					}
				});
				else this.$router.push({
					path: '/street',
					query: {
						keyword: this.keyword
					}
				});
			},
			getHotSearchWords() {
				apiHotSearchWords({}).then(res => {
					if (res.code == 0 && res.data.words) {
						this.hotSearchWords = res.data.words.split(',');
					}
				});
			},
			getDefaultSearchWords() {
				apiDefaultSearchWords({}).then(res => {
					if (res.code == 0 && res.data.words) {
						this.defaultSearchWords = res.data.words;
					}
				});
			},
			// รับข้อมูลตะกร้าสินค้า
			getCartList() {
				apiCartList({})
					.then(res => {
						if (res.code >= 0 && res.data.length) {
							this.cartList = res.data;
							this.cartTotalPrice = 0
							this.cartList.forEach(item => {
								console.log(item.discount_price, 'ราคาต่อหน่วย')
								let _cartTotalPrice = parseFloat(item.discount_price) * parseInt(item.num);
								this.cartTotalPrice += parseFloat(_cartTotalPrice)
								console.log(this.cartTotalPrice)
							});
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
						}
					})
					.catch(res => {});
			},
			imageError(index) {
				this.cartList[index].sku_image = this.defaultGoodsImage;
			},
			// ลบรายการตะกร้าสินค้ารายการเดียว
			deleteCart(index) {
				this.$store
					.dispatch('cart/delete_cart', {
						cart_id: this.cartList[index].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.cartList.splice(index, 1);
							this.cartList.forEach(item => {
								let _cartTotalPrice = parseFloat(item.discount_price) * parseFloat(item.num);
								this.cartTotalPrice += parseFloat(_cartTotalPrice)
							});
							console.log(this.cartTotalPrice)
							this.cartTotalPrice = this.cartTotalPrice.toFixed(2);
							this.$forceUpdate();
						}
					})
					.catch(err => {});
			}
		}
	};
</script>

<style scoped lang="scss">
	.header-in {
		width: $width;
		height: 89px;
		margin: 20px auto 0;

		.logo-wrap {
			width: 240px;
			height: 68px;
			line-height: 68px;
			display: block;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.in-sousuo {
			width: 550px;
			margin-top: 10px;

			.sousuo-box {
				width: 100%;
				height: 36px;
				border: 2px solid $base-color;
				box-sizing: border-box;

				.el-dropdown {
					padding: 0 10px;
					cursor: pointer;

					&::after {
						content: '';
						border-left: 1px solid #cfcfcf;
						margin-left: 5px;
					}
				}

				input {
					width: 380px;
					height: 22px;
					background: none;
					outline: none;
					border: none;
					margin: 4px;
				}

				button {
					border-radius: 0;
					float: right;
				}
			}

			.hot-search-words {
				width: 100%;
				height: 20px;
				margin-top: 5px;
				font-size: 12px;

				span {
					float: left;
				}

				ul {
					overflow: hidden;
					margin: 0;
					height: 21px;
					padding: 0;
					color: $ns-text-color-black;

					li {
						cursor: pointer;
						list-style: none;
						float: left;
						margin-right: 10px;

						&:hover {
							color: $base-color;
						}
					}
				}
			}
		}

		.cart-wrap {
			position: relative;
			float: right;

			.cart {
				margin-top: 10px;
				width: 95px;
				height: 36px;
				padding: 0 28px 0 19px;
				border: 1px solid #dfdfdf;
				color: $base-color;
				font-size: $ns-font-size-sm;
				display: block;
				position: absolute;
				right: 0;
				z-index: 101;

				span {
					cursor: pointer;
					line-height: 38px;
					margin-right: 10px;
				}
			}

			&:hover {
				.cart {
					border-bottom: 1px solid #ffffff;
				}

				.list {
					display: block;
				}
			}

			.list {
				position: absolute;
				top: 47px;
				right: 0;
				width: 340px;
				background: #fff;
				border: 1px solid #dfdfdf;
				display: none;
				z-index: 100;

				h4 {
					height: 25px;
					padding: 6px 8px;
					line-height: 25px;
				}

				.overflow-wrap {
					width: 340px;
					overflow: hidden;

					ul {
						max-height: 335px;

						&.overflow {
							overflow: auto;
							width: 354px;
						}

						li {
							background-color: #fff;
							display: block;
							font-size: 12px;
							padding: 8px 10px;
							position: relative;
							border-bottom: 1px solid #dfdfdf;
							overflow: hidden;

							.img-wrap {
								width: 50px;
								height: 50px;
								margin-right: 5px;
								overflow: hidden;
								float: left;
								text-align: center;
								line-height: 50px;
							}

							.goods-name {
								float: left;
								width: 140px;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
							}

							.operation {
								float: right;
								text-align: right;
								width: 90px;

								p {
									color: $base-color;
									overflow: hidden;
									text-overflow: ellipsis;
								}

								span {
									cursor: pointer;

									&:hover {
										color: $base-color;
									}
								}
							}

							&:last-child {
								border-bottom: 0;
							}
						}
					}
				}

				.total {
					background-color: #fff;
					display: block;
					font-size: 12px;
					padding: 8px 10px;
					position: relative;
					border-bottom: 1px solid #dfdfdf;
					overflow: hidden;
					background-color: #f0f0f0;
					border-bottom: 0;
					left: 0;

					span {
						margin-top: 5px;
						display: inline-block;
						width: 70%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						strong {
							margin: 0 2px;
							color: $base-color;
							font-size: $ns-font-size-base;
						}
					}

					button {
						float: right;
					}
				}

				.empty {
					width: auto;
					height: 70px;
					line-height: 70px;
					text-align: center;
					color: #999;

					i {
						font-size: 28px;
					}

					span {
						display: inline-block;
						font-size: 12px;
						padding-right: 20px;
						margin-left: 10px;
					}
				}
			}
		}
	}
	
	/*! CSS Used from: https://yobazar-be87.kxcdn.com/yobazar/wp-content/cache/minify/58dc8.css ; media=all */
	@media all{
	.ts-banner,.ts-banner *{backface-visibility:hidden;-webkit-backface-visibility:hidden;}
	.ts-banner{overflow:hidden;width:100%;position:relative;text-align:center;}
	.ts-banner.text-left .banner-wrapper{text-align:left;}
	.ts-banner .banner-wrapper{position:relative;}
	.ts-banner .banner-wrapper .banner-bg{overflow:hidden;}
	.ts-banner .banner-wrapper>a{position:absolute;left:0;right:0;bottom:0;top:0;z-index:9;}
	.ts-banner .banner-bg img{position:relative;margin:0 auto;width:100%;-o-object-fit:cover;object-fit:cover;}
	.ts-banner:not(.text-under-image) .box-content{position:absolute;z-index:2;display:table;transition:300ms ease 0s;}
	.ts-banner .box-content h2,.ts-banner.style-arrow .ts-banner-button a{line-height:1.2;color:#fff;}
	.ts-banner .box-content h2{margin-bottom:20px;}
	.ts-banner .ts-banner-button{display:inline-block;overflow:hidden;position:relative;}
	.ts-banner .ts-banner-button .button{text-decoration:none;border-width:0;}
	.left-top .box-content{left:50px;right:auto;top:50px;bottom:auto;}
	.ts-banner.style-arrow .ts-banner-button .button{min-width:0;font-size:110%;line-height:18px;background:transparent;padding:0;text-align:left;border-radius:0;-webkit-transform:translateX(-100%) translateX(22px);transform:translateX(-100%) translateX(22px);transition:transform 350ms ease;}
	.ts-banner.style-arrow:hover .ts-banner-button .button{transform:translateX(0);}
	.ts-banner.style-arrow .ts-banner-button .button span.icon{width:22px;height:12px;display:inline-block;vertical-align:middle;position:relative;}
	.ts-banner.style-arrow .ts-banner-button .button span.icon:before,.ts-banner.style-arrow .ts-banner-button .button span.icon:after{position:absolute;top:50%;transform:translateY(-50%);}
	.ts-banner.style-arrow .ts-banner-button .button span.icon:before{content:'';width:calc(100% - 4px);height:1px;position:absolute;left:0;background:#191919;}
	.ts-banner.style-arrow .ts-banner-button .button span.icon:after{content:'\e649';font-family:'themify';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-size:10px;right:0;color:#191919;}
	.eff-zoom-in .banner-bg img{transition:transform 500ms;}
	.eff-zoom-in:hover .banner-bg img{-webkit-transform:scale(1.12);transform:scale(1.12);}
	.ts-shortcode{position:relative;clear:both;}
	.woocommerce .products .product.product-category,.ts-product-category-wrapper .products .product.product-category{margin-bottom:30px;}
	.products .product-category > .product-wrapper > a,.woocommerce .products .product-category>.product-wrapper>a{width:100%;padding:100% 0 0;display:inline-block;vertical-align:top;border-radius:100%;background:#f2f2f2;overflow:hidden;margin:0 auto;position:relative;}
	.products .product-category .product-wrapper > a img,.woocommerce .products .product-category .product-wrapper > a img{width:auto!important;height:100%;object-fit:cover;position:absolute;top:50%;bottom:0;left:50%;right:auto;transform:translate(-50%,-50%);}
	.products .product-category .product-wrapper>a:after{content:"";position:absolute;top:0;left:0;bottom:0;right:0;border-radius:50%;background:rgba(0, 0, 0, 0.4);opacity:0;transform:scale(0);transition:all 0.35s ease-in-out;}
	.products .product-category .product-wrapper>a:before{content:"\e61a";font-family:'themify';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#fff;position:absolute;top:50%;left:50%;opacity:0;transform:translate(-50%,-50%) scale(0);transition:all 0.35s ease-in-out;z-index:2;}
	.products .product-category .product-wrapper>a:hover:before{opacity:1;transform:translate(-50%,-50%) scale(1);}
	.products .product-category .product-wrapper>a:hover:after{opacity:1;transform:scale(1);}
	.ts-product-category-wrapper.woocommerce .content-wrapper,.ts-product-category-wrapper.woocommerce .products{width:100%;}
	.ts-product-category-wrapper.woocommerce:not(.columns-1) .content-wrapper{width:calc(100% + 30px);}
	.ts-list-of-product-categories-wrapper .list-categories{margin:0;display:block;}
	.ts-list-of-product-categories-wrapper .list-categories:after{display:none;}
	.ts-list-of-product-categories-wrapper .list-categories ul{margin:0;}
	.ts-list-of-product-categories-wrapper.columns-2 .list-categories ul,.ts-list-of-product-categories-wrapper.columns-3 .list-categories ul{-webkit-column-count:2;-moz-column-count:2;column-count:2;-webkit-column-break-inside:avoid;-moz-column-break-inside:avoid;break-inside:avoid;}
	.ts-list-of-product-categories-wrapper.columns-3 .list-categories ul{-webkit-column-count:3;-moz-column-count:3;column-count:3;}
	.ts-list-of-product-categories-wrapper .list-categories ul li{display:inline-block;width:100%;list-style:none;}
	.woocommerce img{height:auto;max-width:100%;}
	.woocommerce ul.cart_list li::after,.woocommerce ul.cart_list li::before{content:" ";display:table;}
	.woocommerce ul.cart_list li::after{clear:both;}
	.woocommerce ul.cart_list li img{float:right;height:auto;}
	.screen-reader-text{clip:rect(1px,1px,1px,1px);height:1px;overflow:hidden;position:absolute!important;width:1px;word-wrap:normal!important;}
	.woocommerce a.remove{display:block;font-size:1.5em;height:1em;width:1em;text-align:center;line-height:1;border-radius:100%;color:red!important;text-decoration:none;font-weight:700;border:0;}
	.woocommerce a.remove:hover{color:#fff!important;background:red;}
	.woocommerce .quantity .qty{width:3.631em;text-align:center;}
	.woocommerce span.onsale{min-height:3.236em;min-width:3.236em;padding:.202em;font-size:1em;font-weight:700;position:absolute;text-align:center;line-height:3.236;top:-.5em;left:-.5em;margin:0;border-radius:100%;background-color:#77a464;color:#fff;font-size:.857em;z-index:9;}
	.woocommerce a.button{font-size:100%;margin:0;line-height:1;cursor:pointer;position:relative;text-decoration:none;overflow:visible;padding:.618em 1em;font-weight:700;border-radius:3px;left:auto;color:#515151;background-color:#ebe9eb;border:0;display:inline-block;background-image:none;box-shadow:none;text-shadow:none;}
	.woocommerce a.button:hover{background-color:#dfdcde;text-decoration:none;background-image:none;color:#515151;}
	.woocommerce a.button:disabled{color:inherit;cursor:not-allowed;opacity:.5;padding:.618em 1em;}
	.woocommerce a.button:disabled:hover{color:inherit;background-color:#ebe9eb;}
	.woocommerce ul.cart_list{list-style:none outside;padding:0;margin:0;}
	.woocommerce ul.cart_list li{padding:4px 0;margin:0;list-style:none;}
	.woocommerce ul.cart_list li::after,.woocommerce ul.cart_list li::before{content:" ";display:table;}
	.woocommerce ul.cart_list li::after{clear:both;}
	.woocommerce ul.cart_list li a{display:block;font-weight:700;}
	.woocommerce ul.cart_list li img{float:right;margin-left:4px;width:32px;height:auto;box-shadow:none;}
	}
	/*! CSS Used from: https://yobazar-be87.kxcdn.com/yobazar/wp-content/cache/minify/efbca.css ; media=all */
	@media all{
	.elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated>.elementor-widget-wrap{padding:10px;}
	@media (max-width:767px){
	.elementor-reverse-mobile>.elementor-container>.elementor-row>:first-child{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10;}
	.elementor-reverse-mobile>.elementor-container>.elementor-row>:nth-child(2){-webkit-box-ordinal-group:10;-ms-flex-order:9;order:9;}
	.elementor-column{width:100%;}
	}
	.screen-reader-text{position:absolute;top:-10000em;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);border:0;}
	.elementor-clearfix:after{content:"";display:block;clear:both;width:0;height:0;}
	.elementor{-webkit-hyphens:manual;-ms-hyphens:manual;hyphens:manual;}
	.elementor *,.elementor :after,.elementor :before{-webkit-box-sizing:border-box;box-sizing:border-box;}
	.elementor a{-webkit-box-shadow:none;box-shadow:none;text-decoration:none;}
	.elementor img{height:auto;max-width:100%;border:none;-webkit-border-radius:0;border-radius:0;-webkit-box-shadow:none;box-shadow:none;}
	.elementor .elementor-widget:not(.elementor-widget-text-editor):not(.elementor-widget-theme-post-content) figure{margin:0;}
	.elementor-section{position:relative;}
	.elementor-section .elementor-container{display:-webkit-box;display:-ms-flexbox;display:flex;margin-right:auto;margin-left:auto;position:relative;}
	@media (max-width:1024px){
	.elementor-section .elementor-container{-ms-flex-wrap:wrap;flex-wrap:wrap;}
	}
	.elementor-section.elementor-section-boxed>.elementor-container{max-width:1140px;}
	.elementor-section.elementor-section-stretched{position:relative;width:100%;}
	.elementor-section.elementor-section-items-middle>.elementor-container{-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
	.elementor-row{width:100%;display:-webkit-box;display:-ms-flexbox;display:flex;}
	@media (max-width:1024px){
	.elementor-row{-ms-flex-wrap:wrap;flex-wrap:wrap;}
	}
	.elementor-widget-wrap{position:relative;width:100%;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-line-pack:start;align-content:flex-start;}
	.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap{display:-webkit-box;display:-ms-flexbox;display:flex;}
	.elementor-widget-wrap>.elementor-element{width:100%;}
	.elementor-widget{position:relative;}
	.elementor-widget:not(:last-child){margin-bottom:20px;}
	.elementor-column{min-height:1px;}
	.elementor-column,.elementor-column-wrap{position:relative;display:-webkit-box;display:-ms-flexbox;display:flex;}
	.elementor-column-wrap{width:100%;}
	@media (min-width:768px){
	.elementor-column.elementor-col-33{width:33.333%;}
	.elementor-column.elementor-col-50{width:50%;}
	.elementor-column.elementor-col-100{width:100%;}
	}
	@media (max-width:767px){
	.elementor-reverse-mobile>.elementor-container>:first-child{-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10;}
	.elementor-column{width:100%;}
	}
	.elementor-element .elementor-widget-container{-webkit-transition:background .3s,border .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;transition:background .3s,border .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;-o-transition:background .3s,border .3s,border-radius .3s,box-shadow .3s;transition:background .3s,border .3s,border-radius .3s,box-shadow .3s;transition:background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;}
	.elementor-button{display:inline-block;line-height:1;background-color:#818a91;font-size:15px;padding:12px 24px;-webkit-border-radius:3px;border-radius:3px;color:#fff;fill:#fff;text-align:center;-webkit-transition:all .3s;-o-transition:all .3s;transition:all .3s;}
	.elementor-button:focus,.elementor-button:hover,.elementor-button:visited{color:#fff;}
	.elementor-button-content-wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;}
	.elementor-button-text{-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;-webkit-box-ordinal-group:11;-ms-flex-order:10;order:10;display:inline-block;}
	.elementor-button span{text-decoration:inherit;}
	@media (max-width:767px){
	.elementor .elementor-hidden-phone{display:none;}
	}
	@media (min-width:768px) and (max-width:1024px){
	.elementor .elementor-hidden-tablet{display:none;}
	}
	@media (min-width:1025px) and (max-width:99999px){
	.elementor .elementor-hidden-desktop{display:none;}
	}
	.elementor-heading-title{padding:0;margin:0;line-height:1;}
	.elementor-section.elementor-section-boxed>.elementor-container{max-width:1230px;}
	.elementor-widget:not(:last-child){margin-bottom:16px;}
	@media (max-width:1024px){
	.elementor-section.elementor-section-boxed>.elementor-container{max-width:1024px;}
	}
	@media (max-width:767px){
	.elementor-section.elementor-section-boxed>.elementor-container{max-width:767px;}
	}
	}
	/*! CSS Used from: https://yobazar-be87.kxcdn.com/yobazar/wp-content/cache/minify/b3a74.css ; media=all */
	@media all{
	.fa{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-variant:normal;text-rendering:auto;line-height:1;}
	.fa{font-family:"Font Awesome 5 Free";}
	.fa{font-weight:900;}
	*{margin:0;padding:0;box-sizing:border-box;}
	*:before,*:after{box-sizing:border-box;}
	.screen-reader-text{display:none;}
	.first{clear:both;}
	.screen-reader-text{clip:rect(1px, 1px, 1px, 1px);position:absolute!important;height:1px;width:1px;overflow:hidden;}
	.screen-reader-text:focus{background-color:#f1f1f1;border-radius:3px;box-shadow:0 0 2px 2px rgba(0, 0, 0, 0.6);clip:auto!important;color:#21759b;display:block;font-size:15px;font-size:0.875rem;font-weight:bold;height:auto;left:5px;line-height:normal;padding:15px 23px 14px;text-decoration:none;top:5px;width:auto;z-index:99990;}
	img{border:0;vertical-align:top;margin:0 0 0;max-width:100%;height:auto;}
	a{cursor:pointer;background:transparent;transition:150ms ease 0s;text-decoration:none;}
	a:hover{text-decoration:underline;-webkit-tap-highlight-color:transparent;-moz-tap-highlight-color:transparent;}
	a:focus{text-decoration:none;}
	a:focus,input[type="submit"]{outline:none!important;}
	a:active{color:inherit;}
	a.button:hover{text-decoration:none;}
	::-webkit-input-placeholder{color:#a7a7a7;}
	:-moz-placeholder{color:#a7a7a7;}
	::-moz-placeholder{color:#a7a7a7;}
	:-ms-input-placeholder{color:#a7a7a7;}
	input[type^="number"]{background-clip:padding-box!important;}
	input[type^="text"]:focus{outline:0;-webkit-tap-highlight-color:transparent;-moz-tap-highlight-color:transparent;-webkit-appearance:none;}
	p{margin:0 0 20px;display:block;}
	ins{text-decoration:none;}
	h2{margin:0 0 20px 0;}
	h3,h5{margin:0 0 14px 0;}
	h3 a:hover{text-decoration:none;}
	form{display:block;}
	label{line-height:18px;padding:0 0 6px 0;display:block;}
	ul{margin-bottom:20px;}
	ul li:last-child{padding-bottom:0;}
	ul li{line-height:24px;padding:5px 0;}
	ul ul{margin:10px 0 0 30px;}
	ul ul ul{margin:8px 0 0 20px;}
	ul li{list-style:disc inside none;}
	ul ul>li{list-style:circle  inside none;}
	ul ul ul>li{list-style:square  inside none;}
	del{text-decoration:line-through;}
	body input[type="text"],body input[type="number"]{-webkit-appearance:none;-moz-appearance:none;appearance:none;outline:0;}
	html input{box-shadow:none;width:100%;border-width:1px;border-style:solid;margin:0 0 10px;padding:7px 15px;border-radius:0;}
	input{transition:350ms ease 0s;}
	input[type^="submit"]{width:auto;margin:0;}
	input[type^="submit"]{cursor:pointer;margin:0;vertical-align:middle;width:auto;}
	html input:focus:invalid:focus{color:#999;}
	html input[type^="text"],html input[type^="number"]{filter:none!important;border-width:1px;border-style:solid;background:#fff;padding:10px 20px;border-radius:40px;text-align:left;width:100%;box-shadow:none;vertical-align:bottom;}
	html input[type^="text"]:hover,html input[type^="text"]:focus,html input:focus:invalid:focus{box-shadow:none;background-image:none;}
	input:hover,input:focus{outline:none!important;}
	html input[type^="button"],html input[type^="submit"]{-webkit-appearance:none;}
	html input[type^="button"]:focus,html input[type^="submit"]:focus{outline:none!important;}
	body input[type='number']{-moz-appearance:textfield;-webkit-appearance:textfield;}
	br{margin:0 0 5px;}
	a.button{display:inline-block;}
	a.button,input[type^="submit"],.woocommerce a.button,.woocommerce a.button:disabled,.elementor-button-wrapper .elementor-button{padding:8px 40px;box-shadow:none;text-shadow:none;border-radius:40px;filter:none;cursor:pointer;text-align:center;background-image:none;text-align:center;border-width:1px;border-style:solid;text-decoration:none;}
	a.button:hover,input[type^="submit"]:hover,.woocommerce a.button:hover{text-shadow:none;filter:none;background-image:none;outline:none;}
	.woocommerce a.button:disabled{color:inherit;cursor:not-allowed;opacity:.5;background-color:#ebe9eb;border-color:#ebe9eb;}
	.woocommerce a.button:disabled:hover{color:inherit;background-color:#ebe9eb;border-color:#ebe9eb;}
	.thumbnail{border-radius:0;position:relative;display:block;}
	.container{margin-right:auto;margin-left:auto;}
	.hidden{display:none!important;}
	.container{padding-left:40px;padding-right:40px;}
	.ts-header .container{padding-left:40px;padding-right:40px;}
	@media (min-width: 768px){
	.container,.ts-header .ts-megamenu.no-stretch-content > .sub-menu > li{max-width:100%;width:100%;}
	}
	@media (min-width: 992px){
	.container,.ts-header .ts-megamenu.no-stretch-content>.sub-menu>li{max-width:100%;width:100%;}
	}
	@media (min-width: 1279px){
	.header-fullwidth .ts-header .container{max-width:100%;width:100%;}
	.header-fullwidth .ts-header .container,.ts-header nav > ul.menu li.ts-megamenu-fullwidth-stretch ul.sub-menu{padding-left:60px;padding-right:60px;}
	.container{max-width:1400px;width:100%;}
	.ts-header .ts-megamenu.no-stretch-content > .sub-menu > li{max-width:1230px;width:100%;}
	}
	@media (min-width: 1660px){
	.header-fullwidth .ts-header .container,.ts-header nav > ul.menu li.ts-megamenu-fullwidth-stretch ul.sub-menu{padding-left:100px;padding-right:100px;}
	}
	.elementor-section .elementor-container.elementor-column-gap-default .elementor-row{margin-left:-15px;margin-right:-15px;width:calc(100% + 30px);}
	.elementor-column-gap-default .elementor-column>.elementor-element-populated>.elementor-widget-wrap{padding:0 5px;margin-top:-10px;margin-bottom:-10px;}
	.ts-header .ts-megamenu.no-stretch-content > .sub-menu > li .elementor-section.elementor-section-stretched{width:100vw!important;left:calc(-50vw + 50%)!important;}
	#page ::-webkit-scrollbar{width:4px;}
	#page ::-webkit-scrollbar-track{background-color:#e6e6e6;}
	#page ::-webkit-scrollbar-thumb{background-color:#191919;}
	.ts-banner .button{min-width:120px;}
	h3 a,ul li > a,.product-name a{text-decoration:none;}
	.heading-title{-ms-word-wrap:break-word;word-wrap:break-word;position:relative;}
	.fa{font-family:"FontAwesome";font-weight:normal;}
	h3 > a:hover{text-decoration:none;}
	header:after,.header-container:after,.ts-megamenu-container:after,.container:after,div.content-wrapper:after,.ts-product .content-wrapper:after,.woocommerce .products:after,.woocommerce.columns-6:after{display:table;content:"";clear:both;}
	.icon-menu-sticky-header .icon:before,.search-table .search-button:before,nav.main-menu > ul.menu li.parent > a:after,.product-group-button > div a:after,.product-group-button-meta > div.button-in a:before,.product-group-button-meta > div.loop-add-to-cart > a.button:before,.cart_list li .cart-item-wrapper a.remove:before{font-family:'themify';font-weight:normal;font-style:normal;letter-spacing:0;}
	.cart_list li .cart-item-wrapper a.remove:before{content:"\e646";}
	nav.main-menu > ul.menu li.parent > a:after{content:"\e64b";}
	.product-group-button > div.quickshop a:after,.search-table .search-button:before{content:"\e610";}
	.product-group-button-meta > div.compare a:before,.product-group-button > div.compare a:after{content:"\e6bb";}
	.product-group-button-meta > div.wishlist a:before,.product-group-button > div.wishlist a:after{content:"\e634";}
	.product-group-button > div.loop-add-to-cart a:after,.product-group-button-meta > div.loop-add-to-cart > a.button:before{content:"\e60d";}
	.product-wrapper .color-swatch > div:after{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;text-transform:none;letter-spacing:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
	.product-wrapper .color-swatch > div:after{content:'\f00c';font-weight:900;}
	.icon-menu-sticky-header .icon:hover{opacity:0.8;}
	.search-table .search-button:before,.search-table .search-button:after{transition:150ms ease;}
	.search-table .search-button:after{content:"";width:14px;height:14px;border-width:2px;border-style:solid;border-color:rgba(25,25,25,0.5);border-top:2px solid #191919;border-radius:50%;text-align:center;display:inline-block;opacity:0;visibility:hidden;position:absolute;top:50%;left:50%;margin-top:-7px;margin-left:-7px;}
	header .logo img{transition:350ms ease 0s;}
	.ts-header{padding:0;display:block;}
	.ts-header .header-container{position:relative;}
	.ts-header:before{position:fixed;top:0;bottom:0;left:0;right:0;content:"";background:rgba(0,0,0,0.6);z-index:991;opacity:0;visibility:hidden;transition:350ms ease 0s;}
	.ts-menu nav>ul{padding:0;margin:0;}
	.ts-menu nav>ul:after{display:table;content:"";clear:both;}
	.ts-menu nav>ul>li{list-style:none;float:left;padding:0;margin:0;}
	.ts-menu nav>ul>li{margin:0 30px 0 0;}
	nav > ul.menu li>a{text-decoration:none;}
	.ts-header nav .menu{margin:0;}
	.ts-header .menu-wrapper{-webkit-box-flex:1;-ms-flex:1;flex:1;}
	.ts-header .menu-wrapper .ts-menu{display:inline-block;}
	.logo-center .logo-wrapper,.menu-center .menu-wrapper{text-align:center;padding-left:0;padding-right:0;}
	.menu-wrapper nav > ul.menu,.menu-wrapper nav>ul{margin:0;text-align:left;}
	.ts-header .menu-wrapper nav > ul.menu,.ts-header .menu-wrapper nav>ul{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row wrap;flex-flow:row wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
	.ts-header nav.main-menu > ul.menu > li,.ts-header nav.main-menu>ul>li{list-style:none;position:relative;padding:0;margin:0 30px 0 0;display:inline-block;}
	.ts-header nav>ul.menu>li:last-child{padding:0;margin:0;}
	.ts-header nav > ul.menu > li:hover,.ts-header nav>ul>li:hover{z-index:993;}
	.ts-header nav > ul.menu > li a:hover,.ts-header nav > ul > li a:hover{text-decoration:none;}
	.ts-header nav > ul.menu > li > a,.ts-header nav>ul>li>a{padding-top:5px;padding-bottom:5px;position:relative;}
	.ts-header nav > ul.menu li:before{line-height:1;display:inline-block;font-size:80%;}
	.ts-header nav > ul.menu li:before{position:absolute;top:50%;left:0;transform:translateY(-50%);}
	.ts-header nav > ul.menu li:before{margin-top:-1px;}
	.ts-menu-drop-icon{position:absolute;right:10px;left:auto;top:50%;line-height:14px;width:14px;transform:translate(0,-50%);font-size:10px;}
	nav.main-menu > ul.menu li.parent>a:after{font-size:8px;margin:2px 0 0 3px;display:inline-block;}
	.ts-header nav > ul.menu li ul.sub-menu{position:absolute;z-index:999;left:-30px;top:100%;margin:0;padding:62px 30px 30px 30px;width:200px;display:none;}
	.ts-header nav > ul.menu li:hover>ul.sub-menu{display:block;-webkit-animation-duration:200ms;-moz-animation-duration:200ms;animation-duration:200ms;animation-delay:250ms;-webkit-animation-delay:250ms;-moz-animation-delay:250ms;-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-name:animation_menu;-moz-animation-name:animation_menu;animation-name:animation_menu;-webkit-transform-origin:0 0 0;-moz-transform-origin:0 0 0;-ms-transform-origin:0 0 0;}
	.ts-header nav > ul.menu li ul.sub-menu:before,.ts-header nav > ul.menu li ul.sub-menu:after{content:"";position:absolute;top:27px;right:0;left:0;bottom:0;z-index:0;background-color:#fff;}
	.ts-header nav > ul.menu li ul.sub-menu:before{box-shadow:0 5px 5px rgba(0,0,0,0.1);}
	.ts-header nav > ul.menu li.ts-megamenu ul.sub-menu:before{border-width:1px 0 0;border-style:solid;}
	.ts-header nav > ul.menu li ul.sub-menu:after{top:28px;background-size:contain;}
	.ts-header nav > ul.menu > li > ul.sub-menu > li{list-style:none;padding:5px 0;position:relative;z-index:2;}
	.ts-header nav > ul.menu li ul.sub-menu>li:first-child{padding-top:0!important;}
	.ts-header nav > ul.menu li ul.sub-menu>li:last-child{padding-bottom:0!important;}
	.ts-header nav .elementor-widget-wp-widget-nav_menu li>a:before{display:none;}
	#page .ts-header nav .elementor-widget-wp-widget-nav_menu li>a{padding-left:0;padding-right:0;}
	.ts-header nav > ul.menu li.ts-megamenu>ul.sub-menu>li{padding:0!important;margin:0 auto;display:block;}
	.ts-header nav > ul.menu li.ts-megamenu-fullwidth ul.sub-menu{width:1230px;}
	.ts-megamenu-container .elementor-widget-container > h5,.ts-megamenu-container .ts-list-of-product-categories-wrapper h3.heading-title{line-height:20px;margin-bottom:25px;cursor:default;}
	.ts-header nav > ul.menu li.ts-megamenu-fullwidth ul.sub-menu{padding-top:80px;padding-bottom:50px;}
	.ts-header .header-bottom nav > ul.menu li ul.sub-menu{padding-top:38px!important;}
	.ts-header .header-bottom nav > ul.menu li ul.sub-menu:before{top:6px;}
	.ts-header .header-bottom nav > ul.menu li ul.sub-menu:after{top:7px;}
	.ts-header .header-bottom nav > ul.menu li.ts-megamenu-fullwidth ul.sub-menu{padding-top:60px!important;}
	.menu-wrapper nav > ul.menu ul.sub-menu li > a{transition:350ms ease 0s;}
	.header-middle>.container,.header-bottom>.container{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:relative;}
	.header-middle>.container{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding-top:15px;padding-bottom:15px;}
	.header-bottom>.container{-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding-top:10px;padding-bottom:10px;}
	.ts-header div.header-right{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;margin-left:auto;}
	.ts-header div.header-left > *,.ts-header div.header-right>*{display:inline-flex;}
	div.header-right>*:not(:last-child){margin-right:10px;}
	.ts-header div.header-right .my-account-wrapper,.ts-header div.header-right .my-wishlist-wrapper,.ts-header div.header-right .shopping-cart-wrapper{line-height:0;}
	.header-middle,.header-bottom{position:relative;border-width:0 0 1px 0;border-style:solid;}
	.header-v2 .header-middle>.container{padding-top:10px;padding-bottom:10px;}
	.my-account-wrapper .account-control > a:hover,.shopping-cart-wrapper a.cart-control:hover,.my-wishlist-wrapper a:hover{text-decoration:none;}
	.shopping-cart-wrapper{cursor:pointer;position:relative;}
	.shopping-cart-wrapper .cart-icon{position:relative;}
	.my-wishlist-wrapper .tini-wishlist svg,.my-account-wrapper .account-control svg,.shopping-cart-wrapper .cart-control svg{padding:20px 10px;box-sizing:content-box;}
	.my-wishlist-wrapper .tini-wishlist,.my-account-wrapper .account-control > a,.shopping-cart-wrapper .cart-control{display:block;position:relative;}
	.dropdown-container .theme-title span,.my-wishlist-wrapper .tini-wishlist .count-number,.shopping-cart-wrapper .cart-control .cart-number{position:absolute;right:0;top:8px;left:auto;width:20px;height:20px;font-size:11px;line-height:20px;text-align:center;border-radius:100%;overflow:hidden;}
	.shopping-cart-wrapper svg path,.my-wishlist-wrapper svg path,.my-account-wrapper svg path{transition:300ms ease;}
	.ts-tiny-account-wrapper{position:relative;}
	.header-right .shopping-cart-wrapper .cart-control{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
	.ts-tiny-cart-wrapper a.cart-control:hover{text-decoration:none;}
	header .my-wishlist-wrapper a span{right:-5px;}
	.ts-search-by-category>form{min-width:334px;max-width:100%;}
	header .logo-wrapper{max-height:70px;padding:0 30px 0 0;}
	.sticky-logo{display:none;}
	.mobile-logo{display:none;}
	header .logo-wrapper a{font-size:40px;display:inline-block;vertical-align:top;}
	header .logo-wrapper a:hover{text-decoration:none;}
	.logo-center>.container>*{-webkit-box-flex:1;-ms-flex:1;flex:1;}
	.shopping-cart-wrapper .dropdown-container,.my-account-wrapper .dropdown-container{display:none;position:absolute;content:"";right:auto;left:50%;top:100%;min-width:170px;padding:36px 30px 30px 30px;}
	.shopping-cart-wrapper:hover .dropdown-container,.my-account-wrapper:hover .dropdown-container{display:block;-webkit-animation-duration:300ms;-moz-animation-duration:300ms;animation-duration:300ms;animation-delay:100ms;-webkit-animation-delay:100ms;-moz-animation-delay:100ms;-webkit-animation-fill-mode:both;-moz-animation-fill-mode:both;animation-fill-mode:both;-webkit-animation-name:animation_menu;-moz-animation-name:animation_menu;animation-name:animation_menu;-webkit-transform-origin:0 0 0;-moz-transform-origin:0 0 0;-ms-transform-origin:0 0 0;z-index:5;}
	.shopping-cart-wrapper .dropdown-container:before,.my-account-wrapper .dropdown-container:before{position:absolute;left:0;right:0;top:6px;bottom:0;content:"";z-index:-1;box-shadow:0 3px 5px rgba(0,0,0,0.2);display:block;}
	.my-account-wrapper .dropdown-container:before,.shopping-cart-wrapper .dropdown-container:before{top:16px;}
	.my-account-wrapper .dropdown-container,.shopping-cart-wrapper .dropdown-container{padding-top:46px;z-index:992;}
	.my-account-wrapper:hover,.shopping-cart-wrapper:hover,.shopping-cart-wrapper:hover .dropdown-container{z-index:996;}
	.shopping-cart-wrapper .dropdown-container{width:400px;}
	header .cart-dropdown-form .form-content{position:relative;z-index:2;}
	.ts-tiny-cart-wrapper .dropdown-container ul.cart_list li{list-style:none;padding:0 4px 20px 0;margin:0 0 20px 0;display:inline-block;width:100%;position:relative;border-width:0 0 1px 0;border-style:solid;}
	.ts-tiny-cart-wrapper .dropdown-container ul.cart_list li:last-child{margin-bottom:0;border-width:0;}
	.cart_list li a.remove:hover{text-decoration:none;}
	.ts-tiny-cart-wrapper .dropdown-container ul.cart_list{padding:0;margin:0 0 0 0;overflow:auto;max-height:422px;position:relative;}
	.dropdown-container .cart_list li > a{float:left;width:100px;}
	.dropdown-container ul.cart_list li img{width:100%!important;margin:0;}
	.dropdown-footer>a{width:100%;float:none;margin:0 0 5px!important;}
	.dropdown-footer>a:last-child{margin-left:4%;}
	.cart_list .subtotal{float:right;margin-top:10px;}
	.ts-tiny-cart-wrapper .total > span.amount{float:right;}
	.ts-tiny-cart-wrapper .total{padding:14px 0 14px 0;border-width:1px 0 0 0;border-style:solid;position:relative;overflow:hidden;}
	.ts-tiny-cart-wrapper .total > span.total-title{display:inline-block;padding-right:5px;vertical-align:middle;line-height:20px;font-weight:500;}
	.shopping-cart-wrapper .dropdown-container .theme-title{display:none!important;}
	.my-account-wrapper .dropdown-container{width:330px;}
	.dropdown-container .dropdown-footer{margin:0;}
	.logged-in .my-account-wrapper .dropdown-container{width:220px;}
	.logged-in .my-account-wrapper .dropdown-container ul{margin-bottom:0;}
	.logged-in .my-account-wrapper .dropdown-container ul li{list-style:none;}
	.my-account-wrapper .dropdown-container a{color:inherit;text-decoration:none;}
	.header-middle .header-right > div:not(.language-currency):nth-last-child(2) .dropdown-container{right:-50px;left:auto;}
	.header-v2 .header-middle .header-right > div:not(.language-currency):nth-last-child(2) .dropdown-container{right:0;left:auto;}
	.header-v2 .header-middle .header-right > div:not(.language-currency):nth-last-child(4) .dropdown-container{right:-100px;left:auto;}
	.header-sticky{position:relative;z-index:993;width:100%;transition:100ms ease 0s;}
	.icon-menu-sticky-header{display:none!important;width:20px;padding:20px 10px;box-sizing:content-box;text-align:center;cursor:pointer;position:relative;top:2px;}
	.icon-menu-sticky-header .icon{display:inline-block;vertical-align:top;line-height:0;transition:350ms ease;}
	.icon-menu-sticky-header .icon svg line{transition:200ms ease;}
	.dropdown-container .theme-title{margin-bottom:40px;}
	.dropdown-container .theme-title span{position:static;display:inline-block;vertical-align:super;margin:0;}
	li>div>h3.product-name{padding-right:20px;margin-bottom:6px;}
	li>div>.price{margin-bottom:8px;}
	.ts-tiny-cart-wrapper .cart_list li > a{width:80px;}
	.ts-tiny-cart-wrapper .cart_list li .cart-item-wrapper{margin-left:100px;}
	.ts-product .content-wrapper > *,.woocommerce .product figure > *,.ts-product-category-wrapper .content-wrapper>*{transition:350ms ease 0s;}
	.woocommerce .content-wrapper:after{display:table;clear:both;content:"";}
	.elementor-widget[data-widget_type*="wp-widget-"] .elementor-widget-container{overflow:hidden;}
	h3 > a,.ts-header .menu-wrapper .ts-menu a,.product .meta-wrapper a{color:inherit;}
	.ts-header .menu-wrapper .ts-menu a,.product .meta-wrapper a{text-decoration:none;}
	.text-left{text-align:left!important;}
	.elementor-button-wrapper .elementor-button{min-width:168px;}
	.elementor-heading-title{color:inherit;}
	.elementor-widget[data-widget_type*="wp-widget-"] ul{margin-bottom:0!important;}
	.elementor-widget[data-widget_type*="wp-widget-"] ul li{list-style:none;position:relative;}
	.elementor-widget[data-widget_type*="wp-widget-"] ul li:first-child{padding-top:0;margin-top:0;}
	.elementor-widget[data-widget_type*="wp-widget-"] ul li:last-child{padding-bottom:0;margin-bottom:0;}
	.elementor-widget[data-widget_type*="wp-widget-"] ul li>a{position:relative;}
	.elementor-widget[data-widget_type*="wp-widget-"] a:hover{text-decoration:underline;}
	.search-table{position:relative;}
	.search-content input[type="text"]{padding:6px 60px 6px 20px;margin:0;width:100%;max-width:100%;font-size:100%;}
	.search-table .search-button{width:60px;text-align:center;position:absolute;top:0;right:0;bottom:0;display:inline-block;margin:0;padding:0!important;border-width:0;font-size:0!important;background:transparent!important;color:inherit!important;}
	.search-table .search-button:before{font-size:15px;line-height:38px;}
	.search-table .search-button input[type^="submit"]{display:none;}
	.ts-search-by-category ::-webkit-input-placeholder{color:#808080;}
	.ts-search-by-category :-moz-placeholder{color:#808080;}
	.ts-search-by-category ::-moz-placeholder{color:#808080;}
	.ts-search-by-category :-ms-input-placeholder{color:#808080;}
	.color-swatch:after{content:'';clear:both;display:table;}
	.color-swatch{margin:0 -3px;}
	.color-swatch > div{margin:3px;padding:0;}
	.color-swatch>div{float:left;}
	.color-swatch > div span{width:23px;height:23px;font-size:0;letter-spacing:0;border-radius:100%;display:inline-block;vertical-align:top;transition:200ms ease 0s;}
	.color-swatch > div:before{content:'';width:25px;height:25px;border-width:2px;border-style:solid;border-color:#fff;border-radius:100%;display:inline-block;vertical-align:top;position:absolute;top:1px;left:-1px;}
	.color-swatch > div:hover:before{width:21px;height:21px;border-width:2px;top:1px;left:1px;z-index:2;}
	.color-swatch > div:before{top:-1px;}
	.color-swatch>div:hover:before{top:1px;}
	.color-swatch > div{position:relative;width:23px;height:23px;}
	.color-swatch > div:after{display:inline-block;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);font-size:8px;line-height:1;text-align:center;color:#fff;text-shadow:0 0 1px rgba(0,0,0,0.1);opacity:0;visibility:hidden;transition:opacity 200ms, visibility 200ms;}
	.color-swatch > div:after{text-shadow:0 0 1px rgba(0,0,0,0.6);}
	.color-swatch > div:hover:after{opacity:1;visibility:visible;}
	.color-swatch>div:hover{cursor:pointer;}
	.cart_list li a.remove{content:"";height:24px;width:24px;font-size:0;line-height:24px;text-align:center;text-indent:0;padding:0;letter-spacing:0;position:absolute!important;left:auto;right:0;top:0;margin:0!important;text-decoration:none;background:transparent!important;z-index:2;}
	.woocommerce ul.cart_list li img{float:left;width:70px;margin:0 15px 0 0;}
	.woocommerce ul.cart_list li a{font-weight:normal;}
	.elementor-widget-container ul.menu li{list-style:none;}
	.elementor-widget-wp-widget-nav_menu div > ul li:last-child{margin-bottom:0;padding-bottom:0;}
	.elementor-widget-wp-widget-nav_menu.columns-2 ul.menu{-webkit-column-count:2;-moz-column-count:2;column-count:2;-webkit-column-break-inside:avoid;-moz-column-break-inside:avoid;break-inside:avoid;}
	.elementor-widget-wp-widget-nav_menu.columns-2 ul.menu li{display:inline-block;width:100%;}
	.woocommerce .products .product{margin:0 0 50px 0;padding:0 15px;position:relative;}
	.woocommerce.columns-6 .products .product{width:16.6%;}
	.woocommerce.columns-1 .products .product{width:100%;}
	.products .product .product-wrapper:after{display:table;clear:both;content:"";}
	.woocommerce .products{margin:0;}
	.woocommerce.columns-6{clear:both;}
	.woocommerce .products{margin:0 -15px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row wrap;flex-flow:row wrap;position:relative;}
	.ts-product.woocommerce.columns-1 .products{margin-left:0;margin-right:0;}
	.ts-product.woocommerce.columns-1 .products .product{padding-left:0;padding-right:0;}
	.list-categories{margin:30px 0 60px;position:relative;flex-basis:100%;}
	.list-categories:after{content:'';height:1px;position:absolute;bottom:0;left:15px;right:15px;border-width:0 0 1px;border-style:solid;}
	.products .product-category>.product-wrapper{text-align:center;}
	.list-categories,.ts-product .content-wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row wrap;flex-flow:row wrap;}
	.woocommerce .products .product .product-wrapper{position:relative;max-width:100%;height:100%;margin-left:auto!important;margin-right:auto!important;overflow:hidden;}
	.woocommerce .products .product .product-wrapper .thumbnail-wrapper img{width:100%;}
	.woocommerce .product a.button:hover,.woocommerce .product a.button:focus{background-image:none;}
	.woocommerce .product .thumbnail-wrapper{position:relative;overflow:hidden;}
	.woocommerce .product .thumbnail-wrapper *{backface-visibility:hidden;-webkit-backface-visibility:hidden;}
	.woocommerce .product .thumbnail-wrapper>a:before{position:absolute;left:0;right:0;bottom:0;top:0;content:"";background:#000;opacity:0;visibility:hidden;z-index:3;transition:350ms ease 0s;}
	.woocommerce .product .thumbnail-wrapper>a{position:relative;display:block;text-decoration:none;z-index:2;transition:300ms ease 0s;}
	.woocommerce .products .product a img{margin:0;box-shadow:none;width:100%!important;}
	.woocommerce .product figure{z-index:1;overflow:hidden;}
	.woocommerce .product figure img:first-child{position:relative;z-index:2;}
	.woocommerce .product figure.has-back-image img:last-child{position:absolute;right:0;top:0;z-index:3;}
	.product-group-button-meta{display:none;}
	body .yith-wcwl-add-to-wishlist{margin:0;}
	.meta-wrapper-2,.product-wrapper .button-in.wishlist i,.yith-wcwl-add-to-wishlist i{display:none;}
	.button-in a{text-decoration:none!important;}
	.woocommerce .product-wrapper .meta-wrapper{padding-top:20px;overflow:hidden;}
	.woocommerce .product .category-name h3{margin-bottom:0;line-height:24px;}
	.woocommerce .product-wrapper .meta-wrapper:after{display:table;clear:both;content:"";}
	.woocommerce .product .product-label{position:absolute;right:auto;left:10px;top:10px;bottom:auto;z-index:2;text-align:center;}
	.woocommerce .product-label > span.onsale,.woocommerce .product-label>span{width:auto;min-width:50px;min-height:20px;position:relative;top:auto;right:auto;left:auto;padding:0;text-align:center;display:block;font-size:12px;line-height:20px!important;margin-bottom:3px;border-radius:20px;}
	.woocommerce .product-label span span{padding:0 8px;display:-webkit-box;display:-ms-flexbox;display:flex;width:100%;height:100%;align-items:center;position:relative;justify-content:center;}
	.products .meta-wrapper>*{margin-bottom:8px;display:block;}
	.products .meta-wrapper>*:last-child{margin-bottom:0;}
	.price,.products .meta-wrapper>.price{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row wrap;flex-flow:row wrap;}
	.woocommerce .quantity input.qty,.quantity input.qty{box-shadow:none;text-shadow:none;background:transparent;border-width:0;border-radius:0;margin:0;padding:0 25px;width:100%;line-height:44px;text-align:center;border-width:1px;border-style:solid;border-radius:40px;}
	.cart-item-wrapper .quantity{float:none;position:relative;width:128px;}
	.quantity .number-button{width:100%;position:relative;display:flex;letter-spacing:0;}
	.quantity .minus,.quantity .plus{border:0;background:transparent;width:30%;height:46px;margin:0!important;line-height:46px;text-align:center;position:absolute;left:0;top:0;padding:0;z-index:2;cursor:pointer;border-radius:0;}
	.quantity .plus{right:0;left:auto;}
	.cart-item-wrapper .quantity{text-align:center;margin:0;width:100px;float:left;}
	.cart-item-wrapper .quantity .qty,.cart-item-wrapper .quantity .minus,.cart-item-wrapper .quantity .plus{height:40px;line-height:40px;}
	.quantity .minus:hover,.quantity .plus:hover{z-index:3;}
	.product-group-button-meta a:hover,.product-group-button a:hover{text-decoration:none;}
	.thumbnail-wrapper .product-group-button>div{font-size:0;line-height:0;padding:0;min-width:0;width:32px;height:32px;display:block;position:relative;letter-spacing:0;background:#fff;border-radius:50%;text-align:center;position:absolute;top:10px;right:10px;z-index:6;transform:translateX(100%) translateX(10px);transition:250ms ease-in-out;}
	.thumbnail-wrapper .product-group-button>div:nth-child(2){top:50px;}
	.thumbnail-wrapper .product-group-button>div:nth-child(3){top:90px;}
	.thumbnail-wrapper .product-group-button>div:nth-child(4){top:130px;}
	.product-group-button > div a:after{font-size:14px;line-height:32px;transition:none;left:auto;right:auto;bottom:auto;top:auto;padding:0;}
	.product-wrapper:hover .product-group-button>div{transform:translateX(0);}
	.product-group-button > div a{width:100%;height:100%;margin:0;position:relative;font-size:0;line-height:0;padding:0;min-width:0;border:0;text-decoration:none;letter-spacing:0;overflow:visible;display:inline-block;vertical-align:top;color:inherit!important;transition:none;}
	.thumbnail-wrapper .product-group-button > div a{display:block;opacity:1!important;}
	.button-in .yith-wcwl-add-to-wishlist,.button-in .yith-wcwl-add-button,.button-in a.add_to_wishlist{width:100%;height:100%;}
	.thumbnail-wrapper .product-group-button > div.loop-add-to-cart a{transition:none;padding:0;margin:0;border-width:0;position:relative;letter-spacing:0;background:transparent!important;color:inherit!important;line-height:inherit!important;}
	.product-group-button .button-tooltip{padding:2px 15px 2px 10px;line-height:26px;min-width:100px;position:absolute;text-transform:none;text-align:center;white-space:nowrap;visibility:hidden;opacity:0;display:inline-block;transition:250ms ease-in-out;}
	.product-group-button .button-tooltip{left:auto;right:100%;top:50%;height:30px;margin:-15px 0 0 0;}
	.product-group-button .button-tooltip:before{position:absolute;top:0;bottom:0;left:0;right:5px;z-index:-1;content:"";border-radius:15px;}
	.product-group-button > div:hover a .button-tooltip{opacity:1;visibility:visible;}
	.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button>div.loop-add-to-cart{width:100%;height:auto;position:absolute;top:auto;left:0;right:0;bottom:0;border-radius:0;transform:translate(0,100%);}
	.product-hover-vertical-style-2 .product-wrapper:hover .thumbnail-wrapper .product-group-button>div.loop-add-to-cart{transform:translate(0,0);}
	.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button > div.loop-add-to-cart a:after,.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button > div.loop-add-to-cart .button-tooltip:before{display:none!important;}
	.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button > div.loop-add-to-cart a.button{border-radius:0;border:0;padding:6px 10px;}
	.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button > div.loop-add-to-cart .button-tooltip{min-width:0;height:auto;margin:0;padding:0;font-size:100%;color:inherit;line-height:inherit;position:static;white-space:normal;visibility:visible;opacity:1;transition:none;}
	.product-group-button-meta>div.loop-add-to-cart>a.button:before{margin-right:15px;font-size:90%;line-height:1;transform:scaleX(-1);display:inline-block;vertical-align:baseline;}
	.products{clear:both;}
	.woocommerce .products .product .meta-wrapper-2 .loop-add-to-cart{margin-bottom:20px;}
	.woocommerce .meta-wrapper-2 .product-group-button-meta .loop-add-to-cart a.button{width:100%;padding-left:10px;padding-right:10px;}
	.woocommerce a.button:disabled{opacity:.2;}
	.product-name > a{color:inherit;}
	.cart_list li .cart-item-wrapper a.remove:before{font-size:12px;}
	.theme-title,.elementor-widget-wrap>.elementor-widget-heading{margin:0 0 30px 0;}
	@media only screen and (min-width: 1279px){
	.woocommerce .product .thumbnail-wrapper figure img:first-child{backface-visibility:hidden;-webkit-backface-visibility:hidden;transition:opacity 600ms ease 0s;}
	.woocommerce .product figure.has-back-image img:last-child{position:absolute;right:0;left:0;top:0;width:100%;transition:opacity 600ms ease 0s;backface-visibility:hidden;-webkit-backface-visibility:hidden;opacity:0;}
	.woocommerce .product .thumbnail-wrapper:hover figure.has-back-image img:last-child{opacity:1;}
	.woocommerce .product .thumbnail-wrapper:hover figure.has-back-image img:first-child{opacity:0;}
	}
	@media only screen and (max-width: 1279px){
	.theme-title,.elementor-widget-wrap>.elementor-widget-heading{margin:0 0 20px 0;}
	.container,.woocommerce .products .product{padding-left:10px;padding-right:10px;}
	.woocommerce .products{margin-left:-10px;margin-right:-10px;}
	.ts-header .container{padding-left:25px;padding-right:25px;}
	body .elementor-section.elementor-section-boxed>.elementor-container{max-width:100%;}
	.elementor-section .elementor-container.elementor-column-gap-default .elementor-row{margin-left:-10px;margin-right:-10px;width:calc(100% + 20px);}
	.elementor-column-gap-default .elementor-column>.elementor-element-populated>.elementor-widget-wrap{padding:0;}
	a.button,input[type^="submit"],.woocommerce a.button,.woocommerce a.button:disabled,.elementor-button-wrapper .elementor-button{padding:8px 30px;}
	header .menu-wrapper .ts-menu{padding:0;}
	.ts-header nav > ul.menu > li > .ts-menu-drop-icon,.ts-header nav>ul>li>.ts-menu-drop-icon{cursor:pointer;position:absolute;left:0;right:0;bottom:0;top:0;z-index:2;transform:none;margin:0;width:auto;height:auto;}
	.ts-header nav > ul.menu li.ts-megamenu-fullwidth ul.sub-menu{padding-top:60px;}
	.ts-header nav > ul.menu li.ts-megamenu-fullwidth ul.sub-menu:before{top:7px;}
	.ts-header nav > ul.menu li.ts-megamenu-fullwidth ul.sub-menu:after{top:8px;}
	.ts-header nav > ul.menu li ul.sub-menu{padding-top:45px;}
	.my-account-wrapper .dropdown-container:before,.shopping-cart-wrapper .dropdown-container:before{top:0px;}
	.my-account-wrapper .dropdown-container,.shopping-cart-wrapper .dropdown-container{padding-top:30px;}
	.header-sticky{z-index:995;}
	.list-categories:after{left:10px;right:10px;}
	.woocommerce.columns-6 .products .product.product-category{width:16.6%;}
	.woocommerce.columns-6 .products .product{width:25%;}
	.woocommerce .product .product-wrapper figure.has-back-image img:last-child{display:none!important;}
	.product .thumbnail-wrapper .product-group-button{display:none;}
	.woocommerce .product-wrapper .meta-wrapper-2{display:block;}
	.product-group-button-meta > div.button-in .ts-tooltip,.product-group-button-meta > div.button-in,.product-group-button-meta > div.button-in a{font-size:0!important;}
	.product-group-button-meta > div.button-in a:before,.product-group-button-meta > div.button-in a:after{font-size:15px;}
	.ts-product .product .product-group-button-meta{display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;background:#f3f3f3;margin:0;}
	.ts-product .product .product-group-button-meta > div{margin:0;-webkit-box-flex:1;-ms-flex:1;flex:1;text-align:center;position:relative;max-height:46px;overflow:hidden;}
	.product-group-button-meta > div.button-in a:before,.ts-product .product-group-button-meta>div.loop-add-to-cart>a.button:before{line-height:46px!important;margin-left:0;margin-right:0;}
	.product-hover-vertical-style-2 .ts-product .products .product .product-group-button-meta div.loop-add-to-cart .button{position:static;}
	.product-hover-vertical-style-2 .ts-product .products .product .product-group-button-meta div.loop-add-to-cart a.button{line-height:46px!important;margin:0;}
	.ts-product .product .product-group-button-meta > div:not(:last-child){border-right:2px solid #fff;}
	.ts-product .product .product-group-button-meta > div a.button{width:auto;min-width:0;padding:0;background:transparent!important;border:none;}
	.ts-product .products .product .meta-wrapper-2 .loop-add-to-cart,.ts-product.woocommerce .products .product .meta-wrapper-2 .loop-add-to-cart{margin-bottom:0;}
	.ts-product .product .product-group-button-meta > div a{display:block;}
	.ts-product .product .product-group-button-meta > div .ts-tooltip{display:none;}
	.ts-product .product .product-wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column wrap;flex-flow:column wrap;}
	.ts-product .product .product-wrapper > div{width:100%;}
	.ts-product .product .product-wrapper .meta-wrapper{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2;}
	.ts-product .product .product-wrapper .meta-wrapper-2{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1;margin:2px 0 0;padding:0;}
	.woocommerce .products .product{margin-bottom:40px;}
	.left-top .box-content{left:30px;top:30px;}
	.ts-banner.style-arrow .ts-banner-button .button{transform:translateX(0);transition:none!important;}
	.ts-banner.style-arrow .ts-banner-button .button>span:not(.icon){display:none;}
	.ts-banner .box-content h2{margin-bottom:15px;}
	.ts-product.woocommerce .products{width:calc(100% + 20px);}
	.woocommerce .products .product.product-category,.ts-product-category-wrapper .products .product.product-category{margin-bottom:20px;}
	}
	@media only screen and (max-width: 1200px){
	.shopping-cart-wrapper:hover .dropdown-container,.my-account-wrapper:hover .dropdown-container{display:none;}
	}
	@media only screen and (max-width: 991px){
	.ts-search-by-category > form{min-width:0;}
	.logo-center>.container>*{-webkit-box-flex:1;-ms-flex:auto;flex:auto;}
	.elementor-button-wrapper .elementor-button{min-width:0;}
	div.header-right>*:not(:last-child){margin-right:5px;}
	.woocommerce.columns-6 .products .product{width:33.333333%;}
	}
	@media only screen and (min-width: 768px) and (max-width: 991px){
	.woocommerce ul.cart_list li img{width:60px;margin:0 10px 0 0;}
	}
	}
	/*! CSS Used from: https://yobazar-be87.kxcdn.com/yobazar/wp-content/cache/minify/87593.css ; media=all */
	@media all{
	@media only screen and (max-width: 767px){
	.hidden-phone{display:none!important;}
	.ts-header .container{padding-left:15px;padding-right:15px;}
	.woocommerce .products{margin:0 -5px;}
	.woocommerce .products .product{padding-left:5px;padding-right:5px;}
	.logo-wrapper .normal-logo{display:none;}
	.logo-wrapper .mobile-logo{display:block;}
	.shopping-cart-wrapper .dropdown-container,.my-account-wrapper .dropdown-container{display:none!important;}
	.header-middle > .container{padding-top:10px;padding-bottom:10px;}
	.header-v2 .logo-center>.container>.header-left{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2;}
	.header-v2 .logo-center>.container>*{-webkit-box-flex:0;-ms-flex:none;flex:none;}
	.header-v2 .header-middle .logo-wrapper{max-width:50%;padding:0 40px 0 0;}
	.header-v2 .header-middle > .container{padding-top:15px;padding-bottom:15px;}
	.header-v2 .logo-center > .container > .header-left{-webkit-box-flex:1;-ms-flex:1;flex:1;max-width:50%;}
	.header-v2 .ts-header div.header-left .ts-search-by-category{margin-left:0;margin-right:0;}
	.header-v2 .ts-header div.header-left .ts-search-by-category,.header-v2 .ts-header div.header-left .ts-search-by-category > form{width:100%;}
	div.header-right>*:not(:last-child){margin-right:0;}
	.ts-header .my-wishlist-wrapper .tini-wishlist svg,.ts-header .my-account-wrapper .account-control svg,.ts-header .shopping-cart-wrapper .cart-control svg{padding-right:0;}
	.ts-megamenu-container .elementor-widget-container > h5,.ts-megamenu-container .ts-list-of-product-categories-wrapper h3.heading-title{margin-bottom:15px;}
	.list-categories:after{left:5px;right:5px;}
	.woocommerce.columns-6 .products .product.product-category{width:25%;}
	.ts-product.woocommerce .products{width:calc(100% + 10px);}
	.ts-product-category-wrapper.woocommerce:not(.columns-1) .content-wrapper{width:100%;}
	}
	@media only screen and (max-width: 480px){
	.header-v2 .ts-search-by-category .search-content input[type="text"]{padding-right:50px;padding-left:15px;}
	.ts-list-of-product-categories-wrapper.columns-3 .list-categories ul{-webkit-column-count:2;-moz-column-count:2;column-count:2;}
	.woocommerce.columns-6 .products .product{width:50%;}
	.woocommerce.columns-6 .products .product.product-category{width:33.3333%;}
	.left-top .box-content{left:30px;top:30px;}
	}
	header .logo img{width:150px;}
	@media only screen and (max-width: 1279px){
	header .logo img{width:150px;}
	}
	@media only screen and (max-width: 767px){
	header .logo img{width:150px;}
	}
	label,input,.product-name,h3.product-name,.ts-header .menu-wrapper .ts-menu .product{font-family:Jost;font-style:normal;font-weight:400;}
	h2,h3,h5{font-family:Jost;font-style:normal;font-weight:500;}
	.woocommerce .product-label > span.onsale,.woocommerce .product-label > span,.cart_list .subtotal,.ts-tiny-cart-wrapper .total,.button,a.button,input[type^="submit"],.woocommerce a.button,.elementor-button-wrapper .elementor-button,.ts-list-of-product-categories-wrapper{font-family:Jost;font-style:normal;font-weight:500;}
	.dropdown-container .theme-title span,.my-wishlist-wrapper .tini-wishlist .count-number,.shopping-cart-wrapper .cart-control .cart-number{font-family:Jost;font-style:normal;font-weight:600;}
	.ts-header .menu-wrapper .ts-menu{font-family:Jost;font-style:normal;font-weight:500;}
	.product-group-button .button-tooltip{font-size:13px;}
	.ts-megamenu-container .elementor-widget-container > h5,.ts-megamenu-container .ts-list-of-product-categories-wrapper h3.heading-title{font-size:14px;}
	.ts-list-of-product-categories-wrapper,.ts-header .menu-wrapper .ts-menu{font-size:17px;line-height:22px;}
	.ts-header nav.main-menu li{line-height:22px!important;}
	.product-name,h3.product-name,.products .meta-wrapper > *:not(.star-rating){font-size:15px;line-height:24px;}
	input{font-size:17px;line-height:24px;}
	.button,a.button,input[type^="submit"],.woocommerce a.button,.elementor-button-wrapper .elementor-button{font-size:20px;line-height:28px;}
	.product-hover-vertical-style-2 .products .product div.loop-add-to-cart a.button{font-size:18px!important;line-height:28px!important;}
	h2{font-size:40px;line-height:50px;}
	h3{font-size:35px;line-height:44px;}
	h5,.dropdown-container .theme-title,.ts-list-of-product-categories-wrapper h3.heading-title{font-size:25px;line-height:32px;}
	.woocommerce .product .category-name h3{font-size:17px;line-height:22px;}
	@media only screen and (max-width: 1279px){
	h2{font-size:35px;line-height:44px;}
	h3{font-size:30px;line-height:40px;}
	h5,.dropdown-container .theme-title,.ts-list-of-product-categories-wrapper h3.heading-title{font-size:22px;line-height:28px;}
	input{font-size:15px;}
	.button,a.button,input[type^="submit"],.woocommerce a.button,.elementor-button-wrapper .elementor-button,.product-hover-vertical-style-2 .products .product div.loop-add-to-cart a.button{font-size:17px;}
	}
	@media only screen and (max-width: 767px){
	h2{font-size:30px;line-height:40px;}
	h3{font-size:25px;line-height:32px;}
	h5,.dropdown-container .theme-title,.ts-list-of-product-categories-wrapper h3.heading-title{font-size:20px;line-height:26px;}
	}
	.shopping-cart-wrapper .dropdown-container:before,.my-account-wrapper .dropdown-container:before{background-color:rgba(255,255,255,1);}
	.ts-product .product .product-group-button-meta > div:not(:last-child),.color-swatch > div:before{border-color:rgba(255,255,255,1);}
	.elementor-widget-container li > a{color:rgba(25,25,25,1);}
	label,html input:focus:invalid:focus{color:rgba(25,25,25,1);}
	.product-group-button .button-tooltip,.thumbnail-wrapper .product-group-button > div:hover{color:rgba(255,255,255,1);}
	.dropdown-container .theme-title span,.my-wishlist-wrapper .tini-wishlist .count-number,.shopping-cart-wrapper .cart-control .cart-number{background:rgba(25,25,25,1);border-color:rgba(25,25,25,1);color:rgba(255,255,255,1);}
	.product-group-button > div a:after,.product-group-button-meta > div.button-in a:before,.ts-product .product-group-button-meta>div.loop-add-to-cart>a.button:before{color:rgba(25,25,25,1);}
	.product-group-button > div:hover a:after{color:rgba(255,255,255,1);}
	.thumbnail-wrapper .product-group-button > div{background:rgba(255,255,255,1);border-color:rgba(255,255,255,1);color:rgba(25,25,25,1);}
	.woocommerce a.remove,.cart_list li a.remove{color:rgba(25,25,25,1)!important;}
	.thumbnail-wrapper .product-group-button > div:hover,.product-group-button .button-tooltip:before{background:rgba(25,25,25,1);}
	h2,h3,h5{color:rgba(25,25,25,1);}
	.ts-header a:hover,.my-account-wrapper .dropdown-container a:hover{color:rgba(217,18,31,1);}
	.cart_list li a.remove:hover{color:rgba(217,18,31,1)!important;}
	.shopping-cart-wrapper:hover .cart-icon svg path,.my-wishlist-wrapper:hover svg path,.my-account-wrapper:hover svg path{stroke:rgba(217,18,31,1);}
	.ts-header .ts-menu{color:rgba(25,25,25,1);}
	.ts-menu ul li > a:hover,.ts-menu ul li.current-menu-item > a,.ts-menu ul .sub-menu li.current-menu-item > a{color:rgba(217,18,31,1)!important;}
	.ts-header nav > ul.menu li ul.sub-menu:before,.ts-header nav > ul.menu li ul.sub-menu:after{background-color:rgba(255,255,255,1);}
	.ts-megamenu-container .elementor-widget-container > h5,.ts-megamenu-container .ts-list-of-product-categories-wrapper h3.heading-title{color:rgba(128,128,128,1);}
	a{color:rgba(217,18,31,1);}
	a:hover,.ts-product-category-wrapper .product .category-name a:hover{color:rgba(217,18,31,1);}
	.button,a.button,input[type^="submit"],.woocommerce a.button,.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button > div.loop-add-to-cart,.woocommerce a.button:disabled{background:rgba(25,25,25,1);border-color:rgba(25,25,25,1);color:rgba(255,255,255,1);}
	.button:hover,a.button:hover,input[type^="submit"]:hover,.woocommerce a.button:hover{color:rgba(25,25,25,1);background:rgba(25,25,25,0);border-color:rgba(25,25,25,1);}
	.product-hover-vertical-style-2 .thumbnail-wrapper .product-group-button>div.loop-add-to-cart:hover{background:rgba(217,18,31,1);}
	.elementor-button-wrapper .elementor-button,.dropdown-footer>a.button.checkout-button{background:rgba(217,18,31,1);border-color:rgba(217,18,31,1);color:rgba(255,255,255,1);}
	.elementor-button-wrapper .elementor-button:hover,.dropdown-footer>a.button.checkout-button:hover{background:rgba(255,255,255,1);color:rgba(217,18,31,1);border-color:rgba(217,18,31,1);}
	html input[type="text"],html input[type="number"]{color:rgba(25,25,25,1);background-color:rgba(255,255,255,1);border-color:rgba(204,204,204,1);}
	.ts-search-by-category > form .search-content input[type="text"]{background-color:rgba(242,242,242,1);border-color:rgba(242,242,242,1);}
	*,.ts-tiny-cart-wrapper .total,.list-categories:after{border-color:rgba(230,230,230,1);}
	.header-middle,.header-bottom{background:rgba(255,255,255,1);color:rgba(25,25,25,1);border-color:rgba(230,230,230,1);}
	.ts-header nav > ul.menu li ul.sub-menu:before,.header-v2 .ts-header nav > ul.menu li ul.sub-menu:before{border-color:rgba(230,230,230,1);}
	.shopping-cart-wrapper .cart-icon svg path,.icon-menu-sticky-header svg line,.my-wishlist-wrapper svg path,.my-account-wrapper svg path{stroke:rgba(25,25,25,1);}
	.product-name,h3.product-name,.woocommerce ul.cart_list li a{color:rgba(25,25,25,1);}
	.product-name:hover,h3.product-name:hover,.woocommerce ul.cart_list li a:hover{color:rgba(217,18,31,1);}
	.price{color:rgba(25,25,25,1);}
	.products .product-category > .product-wrapper > a,.woocommerce .products .product-category>.product-wrapper>a{background:rgba(243,243,243,1);}
	.woocommerce .product .product-label .onsale{color:rgba(255,255,255,1);background:rgba(217,18,31,1);}
	.woocommerce .product .product-label .featured{color:rgba(255,255,255,1);background:rgba(25,25,25,1);}
	.search-table .search-button:after{border-color:rgba(25,25,25,0.5);}
	.search-table .search-button:after{border-top-color:rgba(25,25,25,1);}
	.search-table .search-button:after{border-color:rgba(204,204,204,1);}
	.search-table .search-button:after{border-top-color:rgba(25,25,25,1);}
	@media only screen and (max-width: 1279px){
	.ts-product .product .product-group-button-meta{background:rgba(243,243,243,1);}
	.product-group-button > div a:after,.product-group-button-meta > div.button-in a:before,.ts-product .product-group-button-meta>div.loop-add-to-cart>a.button:before{color:rgba(25,25,25,1);}
	}
	}
	/*! CSS Used from: https://yobazar-be87.kxcdn.com/yobazar/wp-content/cache/minify/b12ba.css ; media=all */
	@media all{
	@media (max-width:767px){
	.elementor-465 .elementor-element.elementor-element-19c296d>.elementor-element-populated{margin:0px 0px 20px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
	.elementor-465 .elementor-element.elementor-element-bc2a020>.elementor-element-populated{margin:0px 0px 20px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
	}
	.elementor-520 .elementor-element.elementor-element-759e707 .list-categories ul li a{color:#191919;}
	.elementor-520 .elementor-element.elementor-element-1cdd86c .list-categories ul li a{color:#191919;}
	.elementor-520 .elementor-element.elementor-element-b7bf221 .list-categories ul li a{color:#191919;}
	@media (max-width:767px){
	.elementor-520 .elementor-element.elementor-element-832ab42>.elementor-element-populated{margin:0px 0px 20px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
	.elementor-520 .elementor-element.elementor-element-30160da>.elementor-element-populated{margin:0px 0px 20px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
	}
	.elementor-534 .elementor-element.elementor-element-3487098{margin-top:0px;margin-bottom:-32px;}
	.elementor-534 .elementor-element.elementor-element-e79fa68 .products .product-category>.product-wrapper>a{background:#f2f2f2;}
	.elementor-528 .elementor-element.elementor-element-04906f5{margin-top:-52px;margin-bottom:0px;}
	.elementor-528 .elementor-element.elementor-element-dc30ff4.elementor-column.elementor-element[data-element_type="column"]>.elementor-column-wrap.elementor-element-populated>.elementor-widget-wrap{align-content:center;align-items:center;}
	.elementor-528 .elementor-element.elementor-element-daacd5c .list-categories ul li a{color:#191919;}
	.elementor-528 .elementor-element.elementor-element-daacd5c>.elementor-widget-container{padding:50px 0px 50px 0px;}
	.elementor-528 .elementor-element.elementor-element-bbb40ec:not(.elementor-motion-effects-element-type-background) > .elementor-column-wrap{background-image:url("https://demo.theme-sky.com/yobazar/wp-content/uploads/2021/03/megamenu-1.jpg");background-position:top right;background-repeat:no-repeat;background-size:cover;}
	.elementor-528 .elementor-element.elementor-element-bbb40ec>.elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;}
	.elementor-528 .elementor-element.elementor-element-37621d9 .elementor-spacer-inner{height:50px;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1>.elementor-container{min-height:340px;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1:not(.elementor-motion-effects-element-type-background){background-image:url("https://demo.theme-sky.com/yobazar/wp-content/uploads/2021/03/megamenu-2.jpg");background-position:center left;background-repeat:no-repeat;background-size:cover;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;margin-top:0px;margin-bottom:-50px;}
	.elementor-528 .elementor-element.elementor-element-346f754.elementor-column.elementor-element[data-element_type="column"]>.elementor-column-wrap.elementor-element-populated>.elementor-widget-wrap{align-content:center;align-items:center;}
	.elementor-528 .elementor-element.elementor-element-42a18dc .elementor-heading-title{color:#FFF;}
	.elementor-528 .elementor-element.elementor-element-64a8142{color:#FFF;font-size:22px;font-weight:400;}
	.elementor-528 .elementor-element.elementor-element-e16bf18 .elementor-button{fill:#FFF;color:#FFF;}
	.elementor-528 .elementor-element.elementor-element-e16bf18 .elementor-button:hover,.elementor-528 .elementor-element.elementor-element-e16bf18 .elementor-button:focus{color:var( --e-global-color-secondary );}
	@media (min-width:768px){
	.elementor-528 .elementor-element.elementor-element-dc30ff4{width:55%;}
	.elementor-528 .elementor-element.elementor-element-bbb40ec{width:45%;}
	}
	@media (max-width:1024px) and (min-width:768px){
	.elementor-528 .elementor-element.elementor-element-214557d{width:40%;}
	.elementor-528 .elementor-element.elementor-element-346f754{width:60%;}
	}
	@media (max-width:1024px){
	.elementor-528 .elementor-element.elementor-element-bbb40ec:not(.elementor-motion-effects-element-type-background) > .elementor-column-wrap{background-position:-75px 0px;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1>.elementor-container{min-height:300px;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1:not(.elementor-motion-effects-element-type-background){background-position:-330px 0px;}
	}
	@media (max-width:767px){
	.elementor-528 .elementor-element.elementor-element-04906f5{margin-top:-28px;margin-bottom:0px;}
	.elementor-528 .elementor-element.elementor-element-dc30ff4>.elementor-element-populated{margin:30px 0px 30px 0px;--e-column-margin-right:0px;--e-column-margin-left:0px;}
	.elementor-528 .elementor-element.elementor-element-bbb40ec:not(.elementor-motion-effects-element-type-background) > .elementor-column-wrap{background-position:top center;}
	.elementor-528 .elementor-element.elementor-element-37621d9 .elementor-spacer-inner{height:220px;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1:not(.elementor-motion-effects-element-type-background){background-position:-306px 0px;}
	.elementor-528 .elementor-element.elementor-element-b93c8c1{margin-top:0px;margin-bottom:0px;padding:0px 60px 0px 60px;}
	.elementor-528 .elementor-element.elementor-element-64a8142{font-size:18px;}
	}
	.elementor-531 .elementor-element.elementor-element-0a3f1ff{margin-top:-50px;margin-bottom:-50px;}
	.elementor-531 .elementor-element.elementor-element-962b2af:not(.elementor-motion-effects-element-type-background) > .elementor-column-wrap{background-image:url("https://demo.theme-sky.com/yobazar/wp-content/uploads/2021/03/megamenu-3.jpg");background-repeat:repeat;background-size:cover;}
	.elementor-531 .elementor-element.elementor-element-962b2af>.elementor-element-populated{transition:background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;}
	.elementor-531 .elementor-element.elementor-element-2b42a22 .list-categories ul li a{color:#191919;}
	.elementor-531 .elementor-element.elementor-element-2b42a22>.elementor-widget-container{padding:50px 0px 50px 0px;}
	.elementor-531 .elementor-element.elementor-element-2856860 .elementor-spacer-inner{height:50px;}
	.elementor-531 .elementor-element.elementor-element-e45073f .banner-bg img{min-height:200px;}
	.elementor-531 .elementor-element.elementor-element-e45073f .box-content h2{font-size:30px;}
	.elementor-531 .elementor-element.elementor-element-e45073f .box-content h2,.elementor-531 .elementor-element.elementor-element-e45073f .style-arrow .ts-banner-button a{color:#fff;}
	.elementor-531 .elementor-element.elementor-element-e45073f .style-arrow .ts-banner-button .button span.icon:before{background:#fff;}
	.elementor-531 .elementor-element.elementor-element-e45073f .style-arrow .ts-banner-button .button span.icon:after{color:#fff;}
	.elementor-531 .elementor-element.elementor-element-85a3f44 .elementor-spacer-inner{height:50px;}
	@media (max-width:1024px){
	.elementor-531 .elementor-element.elementor-element-962b2af:not(.elementor-motion-effects-element-type-background) > .elementor-column-wrap{background-position:-65px 0px;}
	.elementor-531 .elementor-element.elementor-element-e45073f .box-content h2{font-size:25px;}
	.elementor-531 .elementor-element.elementor-element-e45073f>.elementor-widget-container{padding:0px 0px 0px 10px;}
	}
	@media (min-width:768px){
	.elementor-531 .elementor-element.elementor-element-962b2af{width:42%;}
	.elementor-531 .elementor-element.elementor-element-4ec7a7a{width:34%;}
	.elementor-531 .elementor-element.elementor-element-700fc15{width:23.664%;}
	}
	@media (max-width:1024px) and (min-width:768px){
	.elementor-531 .elementor-element.elementor-element-962b2af{width:35%;}
	.elementor-531 .elementor-element.elementor-element-4ec7a7a{width:38%;}
	.elementor-531 .elementor-element.elementor-element-700fc15{width:27%;}
	}
	@media (max-width:767px){
	.elementor-531 .elementor-element.elementor-element-0a3f1ff{margin-top:-28px;margin-bottom:0px;}
	.elementor-531 .elementor-element.elementor-element-962b2af:not(.elementor-motion-effects-element-type-background) > .elementor-column-wrap{background-position:-65px 0px;}
	.elementor-531 .elementor-element.elementor-element-e45073f .box-content h2{font-size:30px;}
	.elementor-531 .elementor-element.elementor-element-e45073f>.elementor-widget-container{padding:0px 0px 0px 0px;}
	}
	}
	/*! CSS Used keyframes */
	@-webkit-keyframes animation_menu{0%{visibility:hidden;transform:scale(1,0.7);}100%{visibility:visible;transform:scale(1,1);}}
	@keyframes animation_menu{0%{visibility:hidden;transform:scale(1,0.7);}100%{visibility:visible;transform:scale(1,1);}}
	/*! CSS Used fontfaces */
	@font-face{font-family:'themify';src:url('https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Themify/themify.eot');src:url('https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Themify/themify.eot?#iefix') format('embedded-opentype'), url('https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Themify/themify.woff') format('woff'), url('https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Themify/themify.ttf') format('truetype'), url('https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Themify/themify.svg') format('svg');font-weight:normal;font-style:normal;}
	@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:400;font-display:block;src:url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-regular-400.eot);src:url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-regular-400.eot?#iefix) format("embedded-opentype"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-regular-400.woff2) format("woff2"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-regular-400.woff) format("woff"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-regular-400.ttf) format("truetype"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-regular-400.svg#fontawesome) format("svg");}
	@font-face{font-family:"Font Awesome 5 Free";font-style:normal;font-weight:900;font-display:block;src:url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-solid-900.eot);src:url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-solid-900.woff2) format("woff2"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-solid-900.woff) format("woff"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-solid-900.ttf) format("truetype"),url(https://yobazar-be87.kxcdn.com/yobazar/wp-content/themes/yobazar/css/fonts/Fontawesome/fa-solid-900.svg#fontawesome) format("svg");}
	@font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oDd4iYl.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73ord4iYl.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
	@font-face{font-family:'Jost';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/jost/v14/92zatBhPNqw73oTd4g.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
	
	
	.form-content::-webkit-scrollbar-track {
	  background: #f1f1f100;
	}
	.form-content::-webkit-scrollbar {
	    width: 0px;
	}
</style>
