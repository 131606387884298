import http from "../../utils/http"

/**
 * รับข้อมูลสมาชิก【เข้าสู่หน้าแรกที่เรียกว่า，คุณไม่สามารถควบคุมการเข้าสู่ระบบได้】
 */
export function memberDetail(params) {
    return http({
        url: "/api/member/info",
        data: params
    })
}

/**
 * ดูรายการที่อยู่
 */
export function addressList(params) {
    return http({
        url: "/api/memberaddress/page",
        data: params,
        forceLogin: true
    })
}

/**
 * ตั้งค่าเป็นค่าเริ่มต้น
 */
export function setDefault(params) {
    return http({
        url: "/api/memberaddress/setdefault",
        data: params,
        forceLogin: true
    })
}

/**
 * ลบที่อยู่
 */
export function deleteAddress(params) {
    return http({
        url: "/api/memberaddress/delete",
        data: params,
        forceLogin: true
    })
}

/**
 * ข้อมูลที่อยู่
 */
export function addressInfo(params) {
    return http({
        url: "/api/memberaddress/info",
        data: params,
        forceLogin: true
    })
}

/**
 * เพิ่มที่อยู่
 */
export function saveAddress(params) {
    return http({
        url: "/api/memberaddress/" + params.url,
        data: params,
        forceLogin: true
    })
}

/**
 * ดูรายการคูปอง
 */
export function couponList(params) {
    let url = "/coupon/api/coupon/memberpage"
    return http({
        url: url,
        data: params,
        forceLogin: true
    })
}

/**
 * รอยเท้าของฉัน
 */
export function footPrint(params) {
    return http({
        url: "/api/goodsbrowse/page",
        data: params,
        forceLogin: true
    })
}

/**
 * ลบไทม์ไลน์
 */
export function delFootprint(params) {
    return http({
        url: "/api/goodsbrowse/delete",
        data: params,
        forceLogin: true
    })
}

/**
 * รายชื่อบัญชี
 */
export function accountList(params) {
	return http({
		url: "/api/memberbankaccount/page",
		data: params,
	})
}

/**
 * ตั้งค่าบัญชีเริ่มต้น
 */
export function accountDefault(params) {
	return http({
		url: "/api/memberbankaccount/setdefault",
		data: params,
	})
}

/**
 * ลบบัญชี
 */
export function delAccount(params) {
	return http({
		url: "/api/memberbankaccount/delete",
		data: params,
	})
}

/**
 * รับวิธีการโอน
 */
export function transferType(params) {
	return http({
		url: "/api/memberwithdraw/transferType",
		data: params,
	})
}

/**
 * รับรายละเอียดบัญชี
 */
export function accountDetail(params) {
	return http({
		url: "/api/memberbankaccount/info",
		data: params,
	})
}

/**
 * ประหยัด
 */
export function saveAccount(params) {
	return http({
		url: "/api/memberbankaccount/" + params.url,
		data: params,
	})
}