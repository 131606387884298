import http from "../../utils/http"

/**
 * หยิบใส่ตะกร้า
 * @param {array} params
 */
export function addToCart(params) {
    return http({
        data: params,
        url: "/api/cart/add",
        forceLogin: true
    })
}

/**
 * รายการรถเข็น
 * @param {array} params
 */
export function cartList(params) {
    return http({
        data: params,
        url: "/api/cart/goodslists"
    })
}

/**
 * ลบตะกร้าสินค้า
 * @param {array} params
 */
export function deleteCart(params) {
    return http({
        data: params,
        url: "/api/cart/delete",
        forceLogin: true
    })
}

/**
 * ปรับเปลี่ยนปริมาณตะกร้าสินค้า
 * @param {array} params
 */
export function editCartNum(params) {
    return http({
        data: params,
        url: "/api/cart/edit",
        forceLogin: true
    })
}

/**
 * รับปริมาณตะกร้าสินค้า
 * @param {array} params
 */
export function getCartCount(params) {
    return http({
        data: params,
        url: "/api/cart/count"
    })
}
