import http from "../../utils/http"

/**
 * รับข้อตกลงการลงทะเบียน
 */
export function getRegisiterAggrement(params) {
    return http({
        url: "/api/register/aggrement",
        data: params
    })
}

/**
 * รับสิทธิประโยชน์สําหรับผู้มาใหม่
 */
export function getRegisterReward(params) {
    return http({
        url: "/memberregister/api/Config/Config",
        data: params
    })
}

/**
 * การลงทะเบียนรหัสผ่านบัญชี
 */
export function register(params) {
    return http({
        url: "/api/register/username",
        data: params
    })
}

/**
 * การลงทะเบียนหมายเลขโทรศัพท์มือถือ
 */
export function registerMobile(params) {
    return http({
        url: "/api/register/mobile",
        data: params
    })
}

/**
 * รับรหัสไดนามิก SMS การลงทะเบียน
 */
export function registerMobileCode(params) {
    return http({
        url: "/api/register/mobileCode",
        data: params
    })
}

/**
 * ลงทะเบียนการกําหนดค่า
 */
export function registerConfig(params) {
    return http({
        url: "/api/register/config",
        data: params
    })
}
