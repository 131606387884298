import http from "../utils/http"

/**
 * พื้น
 * @param {object} params
 */
export function floors(params) {
    return http({
        url: "/api/pc/floors",
        data: params
    })
}

/**
 * รับข้อความค้นหายอดนิยม
 */
export function apiHotSearchWords(params) {
    return http({
        url: "/api/goods/hotSearchWords",
        data: params
    })
}

/**
 * รับข้อความค้นหาเริ่มต้น
 */
export function apiDefaultSearchWords(params) {
    return http({
        url: "/api/goods/defaultSearchWords",
        data: params
    })
}
/**
 * รับลอยหน้าแรก
 */
export function floatLayer(params) {
    return http({
        url: "/api/pc/floatLayer",
        data: params
    })
}
