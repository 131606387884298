import Config from "./config.js"
import JSEncrypt from "jsencrypt"
import axios from "axios"
import { getToken } from "./auth"

//การตัดสินเส้นทาง เมื่อตัดสินhttpยังคงเป็นhttps
var pathTitle = location.protocol;
if(pathTitle == "https:" && Config.baseUrl.indexOf(pathTitle) == -1 || pathTitle == "http:" && Config.baseUrl.indexOf(pathTitle) == -1){
	var value = pathTitle == "https:" ? "http:" : "https:";
	// Config.baseUrl = Config.baseUrl.split(value).join(pathTitle);
	// Config.imgDomain = Config.imgDomain.split(value).join(pathTitle);
}
axios.defaults.baseURL = Config.baseUrl
axios.defaults.headers = {
    "X-Requested-With": "XMLHttpRequest",
    "content-type": "application/json"
}
axios.defaults.responseType = "json"
axios.defaults.timeout = 60 * 1000
// axios.defaults.timeout = 100;// ทดสอบรหัสการหมดเวลาของคําขอ，อย่าลบ

/**
 * httpคําขอเดียว
 * @param {object} params พารามิเตอร์คําขอ
 * @param {integer} successCode อินเทอร์เฟซจะส่งกลับ ID ผลลัพธ์ตามปกติ
 *
 * @returns Promise
 */
export default function request(params, successCode = 0, method = "POST") {

    var url = params.url // เส้นทางการร้องขอ
    var data = {
        app_type: "pc",
        app_type_name: "PC"
    }

    var token = getToken()
    if (token) data.token = token

    // พารามิเตอร์
    if (params.data != undefined) Object.assign(data, params.data)

    //อะซิงโครนัส
    return axios({ url: url, method: method, data })
        .then(res => {
            const { code } = res.data || {}
			if (code == -3 && vue.$route.name != 'closed') {
				vue.$router.push('/closed');
				return;
			}
            if (code == successCode) return res.data
            else return Promise.reject(res.data)
        })
        .catch(error => {
            const { error_code } = error
			
            if (error_code === "TOKEN_ERROR") {
				error.message = 'ข้อผิดพลาดในการเข้าสู่ระบบ'
                vue.$store.dispatch("member/remove_token")
                if (params.forceLogin) {
                    vue.$router.push(`/login?redirect=${encodeURIComponent(vue.$router.history.current.fullPath)}`)
                }
            }

            return Promise.reject(error)
        })
}

/**
 * คําขอที่เกิดขึ้นพร้อมกัน
 * @param {array} params อาร์เรย์ของพารามิเตอร์คําขอพร้อมกัน，ลําดับของวัตถุที่ส่งผ่านไปยังอาร์เรย์คือการจับคู่ data กลาง url
 * @var วิธีการนี้เป็นคําขอพร้อมกัน，ข้อมูลจะถูกส่งคืนหลังจากทําการร้องขอทั้งหมดแล้ว
 * ไม่แนะนําให้ใช้วิธีนี้。
 */
export function conRequest(params) {
    if (Object.prototype.toString.call(params) != "[object Array]") {
        return Promise.reject({ code: -1, msg: "พารามิเตอร์ต้องเป็นอาร์เรย์" })
    }

    //กระบวนการทํางานพร้อมกันแบบซิงโครนัส
    var quest = []
    for (var i = 0; i < url.length; i++) {
        quest.push(
            axios({
                url: params[i].url,
                method: method,
                params: params[i].data
            })
        )
    }

    axios
        .all(quest)
        .then(
            axios.spread(() => {
                // คําขอจะถูกดําเนินการหลังจากเสร็จสิ้นทั้งหมด
                var res = []
                for (let i = 0; i < arguments.length; i++) {
                    res.push(arguments[i].data)
                }

                return res
            })
        )
        .catch(error => {
			const { error_code } = error
			if (error_code === "TOKEN_ERROR") {
				error.message = 'ข้อผิดพลาดในการเข้าสู่ระบบ'
			    vue.$store.dispatch("member/remove_token")
			    if (params.forceLogin) {
			        vue.$router.push(`/login?redirect=${encodeURIComponent(vue.$router.history.current.fullPath)}`)
			    }
			}
            // 'คําขอหมดเวลา，โปรดตรวจสอบเครือข่าย'
            // vue.$message({
            //     message: error,
            //     title: "คำเตือน",type: "warning"
            // })

            return Promise.reject(error)
        })
}
