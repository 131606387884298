import Vue from "vue"
import router from "./router"
import store from "./store"
import VueMeta from "vue-meta"
import Element from "element-ui"
import locale from 'element-ui/lib/locale/lang/th'

import {
  Message
} from 'element-ui'

let messageInstance = null;
const overrideMessage = (options) => {
    if(messageInstance) {
        messageInstance.close()
    }
    messageInstance = Message(options)
}
['error','success','info','warning'].forEach(type => {
    overrideMessage[type] = options => {
        if(typeof options === 'string') {
            options = {
                message:options
            }
        }
        options.type = type
        return overrideMessage(options)
    }
})
Vue.use(Element, { locale })
Vue.prototype.$message = overrideMessage;

import scroll from 'vue-seamless-scroll'

import "element-ui/lib/theme-chalk/index.css"
import "./assets/styles/main.scss"
import "./assets/styles/element-variables.scss"

import VideoPlayer from "vue-video-player"
import "video.js/dist/video-js.css"

import App from "./App.vue"

import Util from "./utils/util"
import Lang from "./utils/lang.js"
import Config from "./utils/config.js"

Vue.prototype.$store = store //รอสักครู่vue
Vue.config.productionTip = false

// ฟังก์ชั่นยูทิลิตี้ทั่วไป
Vue.prototype.$util = Util
Vue.prototype.$img = Util.img
Vue.prototype.$timeStampTurnTime = Util.timeStampTurnTime
Vue.prototype.$copy = Util.copy
window.util = Util
window.img = Util.img
window.timeStampTurnTime = Util.timeStampTurnTime
window.copy = Util.copy

// ชุดภาษา
Vue.prototype.$langConfig = Lang //วัตถุชุดภาษา
Vue.prototype.$lang = Lang.lang //แยกวิเคราะห์ชุดภาษา
window.langConfig = Lang
window.lang = Lang.lang

Vue.prototype.$config = Config

Vue.use(VideoPlayer)
Vue.use(VueMeta)
Vue.use(scroll)

window.vue = new Vue({
    router,
    store,
    metaInfo() {
        return {
            // title: store.getters.siteInfo.site_name || "",
			
			link: [
			      {
			                 rel: "icon",
			                 href: store.getters.siteInfo.logo_square
			      },
			 ],
			       
			
            meta: [
                // { name: "title", content: store.getters.siteInfo.site_name || "" },
				{ name: "viewport", content: "width=1400" }
            ]
        }
    },
    render: h => h(App)
}).$mount("#app")
