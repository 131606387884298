	<template>
    <div class="footer">
		
		
			<div class="Block__StyledHeader-sc-1fb3ila-1 kIMokt" style="padding-bottom: 50px; border-top: 1px solid #ebebeb; " ></div>
			
			
        <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0" style="    border: 1px solid rgb(233 233 233 / 0%);" >
            <el-tab-pane label="ลิงค์" name="first">
                <div>
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)"/></span>
                    </div>

                    <div class="clear"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- <div class="friendly-link" v-if="linkList.length > 0">
			<div class="link-title">ลิงค์</div>
			<div>
				<div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
					<span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)" /></span>
				</div>

				<div class="clear"></div>
			</div>
		</div> -->
		
		
        <div class="footer-top" v-if="shopServiceList.length > 0" style="padding-top: 30px;">
		
		<div class="Block__StyledHeader-sc-1fb3ila-1 kIMokt" style="border-top: 1px solid #ebebeb; " ></div>
		
            <ul class="service">
                <li v-for="(item, index) in shopServiceList" :key="index">
                    <div style="    width: 80px;
    height: 80px
px
;">
						<img :src="$img(item.icon)" alt="" v-if="item.icon">
					</div>
                    <p style="    font-weight: 700;
    font-family: 'sukhumvittadmai';">{{ item.service_name }}</p>
                </li>
            </ul>
        </div>

        









<div data-v-dcd07e26="" class="footer-copyright d-flex text-center" style="background-color: rgb(51, 51, 51);padding: 20px 0px;border-top: 1px solid rgb(240, 244, 245);color: #ffff;font-size: 15px;"><div data-v-dcd07e26="" class="container"><p data-v-dcd07e26="" class="m-0 small" style=" color: #c9c9c9;">Copyright © 2022-2029 <a data-v-dcd07e26="" style="
    color: #ffff;
">{{ copyRight.copyright_desc }}</a> - All rights reserved<span data-v-dcd07e26="" class="sep"> | </span><a v-if="copyRight.gov_record" class="footer-link" :href="copyRight.gov_url" target="_blank" style="
    color: #ffff;
"> {{ copyRight.gov_record }}</a><span data-v-dcd07e26="" class="sep"> | </span><a data-v-dcd07e26=""  target="_blank" rel="noreferrer nofollow" style="
    color: #ffff;
">{{ copyRight.company_name }}</a></p></div></div>

        <div class="footer-bot" style="display: none;"><copy-right /></div>
    </div>
</template>

<script>
	import {
		copyRight,
		shopServiceLists,
		friendlyLink,
        weQrcode,
        websiteInfo
	} from "@/api/website"
	import CopyRight from "./CopyRight"
	import {
		mapGetters
	} from "vuex"
	import {
		helpList
	} from "@/api/cms/help"

	export default {
		props: {},
		data() {
			return {
				shopServiceList: [],
				linkList: [],
				helpList: [],
				ishide: false,
				activeName: "first",
                qrcode:"",
                websiteInfo:""
			}
		},
		computed: {
			...mapGetters(["copyRight","siteInfo"])
		},
		created() {
			this.getShopServiceLists(),
			this.link(),
			this.getHelpList(),
            this.getqrcodeimg(),
            this.getWebSiteInfo()
		},
		mounted() {},
		watch: {},
		methods: {
		    getqrcodeimg(){
                weQrcode({}).then(res => {
                        if (res.code == 0 && res.data) {
                            this.qrcode = res.data
                        }
                    })
                    .catch(err => {
                        this.$notify.error({title: 'Error',message: err.message});
                    })
            },
			getShopServiceLists() {
				shopServiceLists({}).then(res => {
						if (res.code == 0 && res.data) {
							this.shopServiceList = res.data
							console.log(res.data.length);
							console.log(res.data.length);
						
							for (let i = 0; i < res.data.length; i++) {
								
								const obj = JSON.parse(res.data[i].icon);
								
								this.shopServiceList[i].icon = obj.imageUrl;
							}
							
							
						}
					})
					.catch(err => {
						this.shopServiceList = []
					})
			},
			link() {
				friendlyLink({})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.linkList = res.data
						}
					})
					.catch(err => {
						this.$notify.error({title: 'Error',message: err.message});
					})
			},
			linkUrl(url, target) {
				if (!url) return
				if (url.indexOf("http") == -1) {
					if (target) this.$router.pushToTab({
						path: url
					})
					else this.$router.push({
						path: url
					})
				} else {
					if (target) window.open(url)
					else window.location.href = url
				}
			},
			/**
			 * รับรายการช่วยเหลือ
			 */
			getHelpList() {
				helpList()
					.then(res => {
						if (res.code == 0 && res.data) {
							var arr = [];
							arr = res.data.slice(0, 4)
							console.log(arr)
							for (let i = 0; i < arr.length; i++) {
								arr[i].child_list = arr[i].child_list.slice(0, 4);
							}

							this.helpList = arr
							console.log(helpList)
						}
					})
					.catch(err => {})
            },
            //โทรศัพท์ด้านล่างและข้อมูลอื่นๆ 
            getWebSiteInfo(){
                websiteInfo()
                    .then(res => {
                        this.websiteInfo = res.data;
                    })
                    .catch(err => {})
            },
			/**
			 * การโหลดรูปภาพล้มเหลว
			 */
			imageError() {
				this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
			},
			/**
			 * ข้ามไปที่รายการช่วยเหลือ
			 */
			clickToHelp(id) {
				this.$router.push("/cms/help/listother-" + id)
			},
			/**
			 * ข้ามไปช่วยเหลือรายละเอียด
			 */
			clickToHelpDetail(id) {
				this.$router.push("/cms/help-" + id)
			},
			/**
			 * ข้ามไปช่วยเหลือรายละเอียด
			 */
			clickJump(address) {
				location.href= address;
			}
		},
		components: {
			CopyRight
		}
	}
</script>

<style scoped lang="scss">
    .footer {
        .footer-top {
            background-color: #fff;
            .service {
                margin: 0;
                padding: 0;
                width: 1500px;
                margin: 0 auto;
                padding: 50px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #e9e9e9;
                display: flex;
                justify-content: space-around;
                align-items: center;
                li {
                    list-style: none;
                    line-height: 50px;
                    text-align: center;
                    flex: 1;

                    div {
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
						display: flex;
						overflow: hidden;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
							
						img {
							height: 100%;
						}
                    }
                    p {
                        font-size: 16px;
                        line-height: 20px;
                        margin-top: 10px;
                    }
                }
            }
        }

        .footer-center {
            width: 1500px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            .left {
                width: 300px;

                .left-phone {
                    font-size: 26px;
                    font-weight: 600;
                    color: $base-color;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $base-color;
                    margin-bottom: 12px;
                }
                .left-email {
                    font-size: 18px;
                    color: #838383;
                }
            }
            .center {
                width: calc(100% - 550px);
                padding: 0 68px;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: #838383;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .help-li {
                    font-size: 14px;
                    color: #838383;
                    line-height: 30px;
                    cursor: pointer;
                }
                .help-phone{
                    font-size: 15px;
                    color: #838383
                }

                p:hover, .help-li:hover {
                    color: $base-color;
                }
            }
            .right {
                width: 250px;
                text-align: center;

                .el-image {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                p {
                    font-size: 18px;
                    color: #838383;
                }
            }

            .qrcode-hide {
                display: none;
            }
        }

        .footer-bot {
            background: #242424;
            color: #9D9D9D;
        }

        .friendly-link {
            width: $width;
            margin: 0 auto;
            border: 1px solid #e9e9e9;

            .link-title {
                line-height: 30px;
                padding: 10px 0 5px;
                margin: 0px 0 15px;
                border-bottom: 1px solid #e8e8e8;
            }
            .link-item {
                width: 15.5%;
                height: 70px;
                line-height: 47px;
                float: left;
                text-align: center;
                overflow: hidden;
                margin: 0 6px 10px 6px;
                -webkit-transition: opacity 0.3s, box-shadow 0.3s;
                transition: opacity 0.3s, box-shadow 0.3s;
                border: 1px solid #fff;
                border-left: none;
                cursor: pointer;
            }
            .link-item:hover {
                width: 15.5%;
                width: 15.5%;
                margin-left: 4px;
                position: relative;
                opacity: 1;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border: 1px solid #dddddd;
            }
            .clear {
                clear: both;
            }
        }
    }
</style>
<style lang="scss">
    .friendly-link {
        .el-tabs__nav-scroll {
            padding-left: 30px;
            height: 50px;
            line-height: 50px;
        }
        .el-tabs__content {
            padding: 0 20px;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
        }
        .el-tabs__nav {
            padding: 0 20px;
        }
        .el-tabs__active-bar {
            padding: 0 20px;
        }
    }
 /*! CSS Used from: Embedded */
  .iYQnIM:hover {
    background-color: rgb(166, 25, 46);
  }

  .fUzDHX {
    background-color: rgb(51, 51, 51);
  }

  .tIyQe:hover {
    background-color: rgb(166, 25, 46);
  }

  /*! CSS Used from: Embedded */
  a {
    background-color: transparent;
  }

  img {
    border-style: none;
  }

  input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  input {
    overflow: visible;
  }

  /*! CSS Used from: Embedded */
  * {
    -webkit-overflow-scrolling: touch;
  }

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
  }

  a {
    color: #0074c2;
  }

  p {
    margin: 0;
    font-weight: 400;
  }

  input {
    font-family: inherit;
    outline: none;
  }

  ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  ::selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  img {
    vertical-align: middle;
  }

  @media print {

    *,
    :after,
    :before {
      background: transparent !important;
      color: #000 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " ("attr(href) ")";
    }

    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p {
      orphans: 3;
      widows: 3;
    }
  }

  /*! CSS Used from: Embedded */
  ._3pSVv {
    display: -ms-flexbox;
    display: flex;
  }

  ._3pSVv.F0sHG {
    width: 100%;
    -ms-flex-pack: center;
    justify-content: center;
  }

  ._1XpNO {
    max-width: 1440px;
  }

  ._1XpNO.F0sHG {
    width: 100%;
  }

  /*! CSS Used from: Embedded */
  ._33BvM {
    color: inherit;
    text-decoration: none;
  }

  /*! CSS Used from: Embedded */
  .aUVIk {
    max-width: 100%;
  }

  /*! CSS Used from: Embedded */
  ._2C_VT {
    height: 80px;
  }

  .hF0T3 {
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 20px;
  }

  @media only screen and (max-width:768px) {
    .hF0T3 {
      display: none;
    }
  }

  ._1l6QA {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .VZexx {
    font-size: 13px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ._3QQhM {
    margin-right: 37px;
    font-size: 14px;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  @media only screen and (max-width:768px) {
    ._3QQhM {
      margin: 0;
    }

    ._3QQhM ._1kFCl {
      display: none;
    }
  }

  ._3QQhM .V6s5E {
    margin-right: 10px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }

  @media only screen and (max-width:768px) {
    ._3QQhM .V6s5E {
      display: none;
    }
  }

  ._3QQhM .V6s5E:hover {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }

  .RNwf5 {
    width: 287px;
    line-height: 51px;
    height: 51px;
  }

  ._2cNMC {
    font-size: 12px;
    color: #333;
  }

  ._2cNMC::-webkit-input-placeholder {
    color: #333;
  }

  ._2cNMC::-moz-placeholder {
    color: #333;
  }

  ._2cNMC::-ms-input-placeholder {
    color: #333;
  }

  ._2cNMC::placeholder {
    color: #333;
  }

  ._3j1UZ {
    width: 18px;
    margin-right: 8px;
  }

  @media only screen and (max-width:768px) {
    ._3j1UZ {
      width: 36px;
      margin-right: 0;
    }
  }

  ._1UcA9 {
    display: block;
  }

  @media only screen and (max-width:768px) {
    ._1UcA9 {
      display: none;
    }
  }

  /*! CSS Used from: Embedded */
  ._2ihbb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  ._2ihbb ._2VHoG {
    padding: 5px;
    cursor: pointer;
  }

  @media only screen and (max-width:768px) {
    ._2ihbb ._2VHoG {
      padding: 2px;
    }
  }

  ._2ihbb ._2VHoG img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }

  @media only screen and (max-width:768px) {
    ._2ihbb ._2VHoG img {
      width: 38px;
      height: 38px;
    }
  }

  ._2ihbb ._2VHoG img:hover {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }

  /*! CSS Used from: Embedded */
  .jV2Jt {
    background-color: #fff;
    border: 1px solid #dfdfdf;
    height: 40px;
    padding: 0 10px;
  }

  .jV2Jt._3q8Ja {
    height: 34px;
  }

  .jV2Jt._3HyCw {
    border-radius: 2px;
  }

  ._3vuaX {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex: 1 1;
    flex: 1 1;
  }

  ._3vuaX,
  ._5vxBY {
    width: 100%;
    height: 100%;
  }

  ._5vxBY {
    border: 0;
    outline: 0;
  }

  ._5vxBY::-webkit-input-placeholder {
    color: #9b9b9b;
  }

  ._5vxBY::-moz-placeholder {
    color: #9b9b9b;
  }

  ._5vxBY::-ms-input-placeholder {
    color: #9b9b9b;
  }

  ._5vxBY::placeholder {
    color: #9b9b9b;
  }

  ._5vxBY._3q8Ja {
    padding-left: 14px;
  }

@media all{
*,::after,::before{box-sizing:border-box;}
p{margin-top:0;margin-bottom:1rem;}
a{color:#536de6;text-decoration:none;background-color:transparent;}
a:hover{color:#1e3dce;text-decoration:underline;}
.small{font-size:80%;font-weight:400;}
.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
.d-flex{display:-ms-flexbox!important;display:flex!important;}
.m-0{margin:0!important;}
.text-center{text-align:center!important;}
@media print{
*,::after,::before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
p{orphans:3;widows:3;}
.container{min-width:992px!important;}
}
}
/*! CSS Used from: https://zy.bet99.top/wp-content/themes/ripro-v2/assets/css/app.css?ver=3.9.0 ; media=all */
@media all{
*,*:before,*:after{-webkit-box-sizing:inherit;box-sizing:inherit;}
::-webkit-scrollbar{background-color:rgba(24, 24, 24, .07);width:8px;height:8px;}
::-webkit-scrollbar-thumb{background-color:rgba(24, 24, 24, .3);border-radius:6px;-webkit-transition:background 200ms ease;-moz-transition:background 200ms ease;transition:background 200ms ease;}
::-webkit-scrollbar-thumb:hover{background-color:#34495e;}
::-webkit-scrollbar-button{display:none;}
a{color:#34495e;text-decoration:none;}
a:hover,a:active,a:focus{color:inherit;text-decoration:none;-webkit-transition:all 0.3s ease;-o-transition:all 0.3s ease;transition:all 0.3s ease;}
p{margin:0 0 10px;}
.site-footer a{color:#555;margin:0 5px;}
.site-footer .footer-copyright{padding:20px 0;border-top:solid 1px #f0f4f5;}
@media (min-width: 1200px){
.wide-screen .container{max-width:1440px;}
}
}/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h4{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h4{font-size:calc(1.275rem + .3vw);}
@media (min-width:1200px){
h4{font-size:1.5rem;}
}
p{margin-top:0;margin-bottom:1rem;}
img{vertical-align:middle;}
::-moz-focus-inner{padding:0;border-style:none;}
.container{width:100%;padding-right:var(--bs-gutter-x,.75rem);padding-left:var(--bs-gutter-x,.75rem);margin-right:auto;margin-left:auto;}
@media (min-width:576px){
.container{max-width:540px;}
}
@media (min-width:768px){
.container{max-width:720px;}
}
@media (min-width:992px){
.container{max-width:960px;}
}
@media (min-width:1200px){
.container{max-width:1140px;}
}
@media (min-width:1400px){
.container{max-width:1320px;}
}
.row{--bs-gutter-x:1.5rem;--bs-gutter-y:0;display:flex;flex-wrap:wrap;margin-top:calc(var(--bs-gutter-y) * -1);margin-right:calc(var(--bs-gutter-x) * -.5);margin-left:calc(var(--bs-gutter-x) * -.5);}
.row>*{flex-shrink:0;width:100%;max-width:100%;padding-right:calc(var(--bs-gutter-x) * .5);padding-left:calc(var(--bs-gutter-x) * .5);margin-top:var(--bs-gutter-y);}
@media (min-width:576px){
.col-sm-6{flex:0 0 auto;width:50%;}
}
@media (min-width:992px){
.col-lg-6{flex:0 0 auto;width:50%;}
}
@media (min-width:1200px){
.col-xl-3{flex:0 0 auto;width:25%;}
}
/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/style.css */
*{margin:0;padding:0;-webkit-box-sizing:border-box;box-sizing:border-box;}
h4{font-weight:400;}
img{display:block;width:100%;height:auto;}
section{display:block;position:relative;padding:60px 0;width:100%;}
@media screen and (max-width: 479px){
section{padding:50px 0;}
}
::-webkit-scrollbar{width:4px;height:4px;}

::-webkit-scrollbar-thumb{background-color:#a7a7a7;border-radius:10px;}
::-moz-selection{background:#a7a7a7;color:#fff;}
::selection{background:#a7a7a7;color:#fff;}
.row > *{max-width:100%;padding-right:calc(var(--bs-gutter-x) * 1);padding-left:calc(var(--bs-gutter-x) * 1);margin-top:var(--bs-gutter-y);}
h4{font-family:"Prompt", sans-serif;font-size:24px;line-height:30px;font-weight:500;}
p{font-family:"Prompt", sans-serif;font-size:16px;font-weight:400;line-height:24px;color:#403c39;}
.feature__section{padding:90px 0;}
.feature__single__item{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:30px 20px;border-radius:16px;}
@media screen and (max-width: 1440px){
.feature__single__item{padding:30px 15px;}
}
@media screen and (max-width: 1199px){
.feature__single__item{margin-bottom:30px;}
}
.feature__single__item .feature__image{margin-right:15px;}
.feature__single__item .feature__image img{height:54px;width:53px;display:block;-webkit-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out;}
.feature__single__item .feature__content p{font-size:16px;font-weight:400;line-height:24px;color:#403c39;letter-spacing:-0.02em;margin-bottom:0;}
@media screen and (max-width: 1440px){
.feature__single__item .feature__content p{font-size:13px;}
}
.feature__single__item .feature__title{font-size:22px;color:#403c39;line-height:24px;display:block;margin-bottom:6px;}
@media screen and (max-width: 1440px){
.feature__single__item .feature__title{font-size:18px;}
}
@media screen and (max-width: 479px){
.feature__single__item .feature__title{font-size:18px;}
}
.feature__single__item:hover .feature__image img{-webkit-transform:scale(1.1);transform:scale(1.1);}
@media print{
*{color:#000000!important;}
}
/*! CSS Used fontfaces */
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:100;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:200;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format('woff2');unicode-range:U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format('woff2');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format('woff2');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}/*! CSS Used from: Embedded */
.kIMokt{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
@media only screen and (max-width:768px){
.kIMokt{width:100%;}
}
.dvxcxc{font-size:26px;font-weight:bold;text-transform:uppercase;color:#333333;margin:0 0 4px 0;}
@media only screen and (max-width:768px){
.dvxcxc{width:100%;font-size:18px;}
}
.jwKbGT{font-size:26px;font-weight:bold;text-transform:uppercase;color:#333333;margin-bottom:4px;display:inline-block;}
@media only screen and (max-width:768px){
.jwKbGT{background:#ffffff;padding:0 10px;display:inline;font-size:18px;width:100%;}
}
.gxBVsG{overflow:hidden;text-align:center;}
@media only screen and (max-width:768px){
.gxBVsG{position:relative;}
}
.gxBVsG::before,.gxBVsG::after{content:'';display:inline-block;position:relative;vertical-align:middle;background-color:#aaaaaa;height:1px;}
.gxBVsG::before{right:30px;width:200px;}
@media only screen and (max-width:768px){
.gxBVsG::before{content:'';margin:0 auto;position:absolute;top:50%;left:0;right:0;bottom:0;width:95%;z-index:-1;}
}
@media (max-width:479px){
.gxBVsG::before{width:99%;}
}
.gxBVsG::after{left:30px;width:200px;}
@media only screen and (max-width:768px){
.gxBVsG::after{display:none;}
}
/*! CSS Used from: Embedded */
.kIMokt{display:flex;-webkit-box-pack:center;justify-content:center;-webkit-box-align:center;align-items:center;}
@media only screen and (max-width: 768px){
.kIMokt{width:100%;}
}
.dvxcxc{font-size:26px;font-weight:bold;text-transform:uppercase;color:rgb(51, 51, 51);margin:0px 0px 4px;}
@media only screen and (max-width: 768px){
.dvxcxc{width:100%;font-size:18px;}
}
.jwKbGT{font-size:26px;font-weight:bold;text-transform:uppercase;color:rgb(51, 51, 51);margin-bottom:4px;display:inline-block;}
@media only screen and (max-width: 768px){
.jwKbGT{background:rgb(255, 255, 255);padding:0px 10px;display:inline;font-size:18px;width:100%;}
}
.gxBVsG{overflow:hidden;text-align:center;}
@media only screen and (max-width: 768px){
.gxBVsG{position:relative;}
}
.gxBVsG::before,.gxBVsG::after{content:"";display:inline-block;position:relative;vertical-align:middle;background-color:rgb(170, 170, 170);height:1px;}
.gxBVsG::before{right:30px;width:200px;}
@media only screen and (max-width: 768px){
.gxBVsG::before{content:"";margin:0px auto;position:absolute;inset:50% 0px 0px;width:95%;z-index:-1;}
}
@media (max-width: 479px){
.gxBVsG::before{width:99%;}
}
.gxBVsG::after{left:30px;width:200px;}
@media only screen and (max-width: 768px){
.gxBVsG::after{display:none;}
}
/*! CSS Used from: Embedded */
*{-webkit-overflow-scrolling:touch;}
*{-webkit-box-sizing:border-box;box-sizing:border-box;outline:none;}
h2{margin:0;font-weight:400;}
::-moz-selection{background:#b3d4fc;text-shadow:none;}
::selection{background:#b3d4fc;text-shadow:none;}
@media print{
*,:after,:before{background:transparent!important;color:#000!important;-webkit-box-shadow:none!important;box-shadow:none!important;text-shadow:none!important;}
h2{orphans:3;widows:3;}
h2{page-break-after:avoid;}
}.footer .friendly-link {
    width: 1210px;
    margin: 0 auto;
    border: 1px solid #e9e9e900;
}.footer .friendly-link .link-item {
    width: 15.5%;
    height: 106px;
    line-height: 47px;
    float: left;
    text-align: center;
    overflow: hidden;
    margin: 0 61px 10px 6px;
    transition: opacity 0.3s, box-shadow 0.3s;
    border: 1px solid #fff;
    border-left: none;
    cursor: pointer;
}
.footer .friendly-link {
    display: block !important;
   
}
.friendly-link .el-tabs__nav-scroll {
    
    height: 0px;
  
}.el-footer{
    padding: 0;
    height: auto !important;
    background-color: #fff;
    padding-top: 0px;
}.footer .footer-top .service {
    margin: 0;
    padding: 0;
    width: 1110px;
    margin: 0 auto;
    padding: 29px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    justify-content: space-around;
    align-items: center;
}</style>