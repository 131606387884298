import http from "../utils/http"

/**
 * ส่งข้อความ
 * @param {object} params
 */
export function sendMessage(params) {
    return http({
        url: "/servicer/api/chat/say",
        data: params
    })
}
/**
 * ส่งรูปภาพ
 * @param {object} params
 */
export function sendImg(params) {
    return http({
        url: "/api/upload/chatimg",
        data: params
    })
}
/**
 * ระบุว่าข้อความถูกอ่านแล้ว
 * @param {object} params
 */
export function readMessage(params) {
    return http({
        url: "/servicer/api/chat/setRead",
        data: params
    })
}

// ผูกการบริการลูกค้า(ลูกค้าid,ร้านid)client_id，site_id
export function bindServicer(params) {
    return http({
        url: '/servicer/api/chat/bind',
        data: params
    })
}

// รับประวัติการแชท
export function messageList(params) {
    return http({
        url: '/servicer/api/chat/dialogs',
        data: params
    })
}

// ไม่ว่าจะออนไลน์หรือไม่ก็ตาม
export function hasServicers(params) {
    return http({
        url: '/servicer/api/chat/hasServicers',
        data: params
    })
}

// รับที่ติดต่อ
export function sessionList(params) {
    return http({
        url: '/servicer/api/chat/chatList',
        data: params
    })
}

// รับที่ติดต่อ
export function currStore(params) {
    return http({
        url: '/api/shop/info',
        data: params
    })
}

// รับที่ติดต่อservicer_site_id
export function groupList(params) {
    return http({
        url: '/servicer/api/servicer/getGroupList',
        data: params
    })
}

// การบริการลูกค้าออนไลน์หรือไม่
export function isHaveServicers(params) {
    return http({
        url: '/servicer/api/chat/hasServicers',
        data: params
    })
}

//ปิดบริการลูกค้า
export function closeMessage(params) {
    return http({
        url: '/servicer/api/chat/bye',
        data: params
    })
}

// รับคําหลัก
export function getKeywords(){
	return http({
		url: '/servicer/api/chat/keyword',
	})
}

// รับคําหลัก
export function getSiteInfo(){
	return http({
		url: '/api/site/info',
	})
}